import React from "react";

import FormPesquisaRapida from "./FormPesquisaRapida";
import FormPesquisaReduzida from "./FormPesquisaReduzida";

export default function PesquisaRapida({
  setFilteredCarros,
  infoJson,
  allLista,
  avancada,
}) {
  const selecionaPesquisaRapida = (tipo) => {
    if (tipo === 1) {
      return (
        <FormPesquisaRapida
          Array={allLista}
          setFilteredCarros={setFilteredCarros}
          tipo="/"
          infoJson={infoJson}
          avancada={avancada}
        />
      );
    } else {
      return (
        <FormPesquisaReduzida
          Array={allLista}
          tipo="/"
          infoJson={infoJson}
          avancada={avancada}
        />
      );
    }
  };

  return <> {selecionaPesquisaRapida(infoJson.Pesquisa.rapida.tipo)}</>;
}
