import React, { useState } from "react";
import css from "./menuMobile.module.css";

import messenger from "../../../../assets/icons/redes/messenger.svg";
import whats from "../../../../assets/icons/redes/whats.svg";
import InfoMenu from "./InfoMenu";
import { tratarLink } from "../../../helpers/tratarInfoEmpresa";
import { ReactComponent as Ligar } from "../../../../assets/icons/redes/liga.svg";
import { ReactComponent as LigueMe } from "../../../../assets/icons/redes/ligue_me.svg";
import { ReactComponent as Email } from "../../../../assets/icons/redes/email.svg";
import ModalLigueMe from "../../../tools/dot/ModalLigueMe";

export default function MenuFala({ allinfo, infoJson }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="col p-3">
        <div className={`${css.menuMobileTitulo} cor-titulo `}>
          Fale agora connosco
        </div>
        <div className={`${css.menuMobileRedes} col nopadding `}>
          {allinfo.Telefone && (
            <>
              <InfoMenu link={tratarLink(allinfo.Telefone, "Telefone")}>
                <div className={`menu ${css.btn}`}>
                  <Ligar height="22px" width="22px" className="SVGColor" />
                </div>
                Ligar
              </InfoMenu>
              <div className={css.with}></div>
            </>
          )}
          {allinfo.Email && (
            <>
              <InfoMenu link={tratarLink(allinfo.Email, "Email")}>
                <div className={`menu ${css.btn}`}>
                  <Email height="20px" width="24px" className="SVGColor" />
                </div>
                Email
              </InfoMenu>
              <div className={css.with}></div>
            </>
          )}
          {infoJson.Layout.LigaMe && (
            <>
              <div
                className={`${css.menuMobileRedesItem1}`}
                onClick={() => setShow(true)}
              >
                <div className={`menu ${css.btn}`}>
                  <LigueMe height="22px" width="22px" className="SVGColor" />
                </div>
                Nós Ligamos
              </div>
              <div className={css.with}></div>
            </>
          )}

          {allinfo.Whatsapp && (
            <>
              <InfoMenu
                link={tratarLink(allinfo.Whatsapp, "Whatsapp")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={`menu ${css.btn}`}>
                  <img
                    height="34px"
                    className={` `}
                    src={whats}
                    alt="Whatsapp"
                  />
                </div>
                Whatsapp
              </InfoMenu>
              <div className={css.with}></div>
            </>
          )}

          {allinfo.LinkMessenger && (
            <>
              <InfoMenu
                link={tratarLink(allinfo.LinkMessenger, "Messenger")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={`menu ${css.btn}`}>
                  <img
                    height="29px"
                    className={` `}
                    src={messenger}
                    alt="Messenger"
                  />
                </div>
                Messenger
              </InfoMenu>
              <div className={css.with}></div>
            </>
          )}
        </div>
      </div>
      <ModalLigueMe
        infoJson={infoJson}
        allinfo={allinfo}
        show={show}
        setShow={setShow}
      />
    </>
  );
}
