import React, { Suspense, useEffect, useState } from "react";

const SectionZone = React.lazy(() => import("../../pages/home/SectionZone"));
const Stand = React.lazy(() => import("./pontoVenda/Stand"));
const FooterInfo = React.lazy(() => import("./FooterInfo"));
const FooterEmpresa = React.lazy(() => import("./infoEmpresa/FooterEmpresa"));

export default function Footer({
  allinfo,
  infoJson,
  infoPrincipal,
  allinfoText,
}) {
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });

  useEffect(() => {
    allinfoText.Localizacao && setAlltexto(allinfoText.Localizacao);
  }, [allinfoText]);

  return (
    <>
      <Suspense fallback={<></>}>
        {infoJson.Layout.PontosVenda.existe && (
          <>
            <SectionZone
              Titulo={
                alltexto.Titulo !== "" ? alltexto.Titulo : "Onde estamos?"
              }
              SubTitulo={
                alltexto.SubTitulo !== "" ? alltexto.SubTitulo : "Visite-nos!"
              }
            >
              <Stand allinfo={allinfo} infoJson={infoJson} />
            </SectionZone>
          </>
        )}
        {infoPrincipal.length !== 0 && (
          <>
            <FooterEmpresa
              allinfo={infoPrincipal[0]}
              infoJson={infoJson}
              allinfoText={allinfoText}
            />
            <FooterInfo
              allinfo={infoPrincipal[0]}
              infoJson={infoJson}
              allinfoText={allinfoText}
            />
          </>
        )}
      </Suspense>
    </>
  );
}
