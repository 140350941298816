import React from "react";
import { Container, Placeholder, Row } from "react-bootstrap";
import Spacer from "../../pages/home/Spacer";
import css from "../../pages/home/home.module.css";

export default function PageHolder() {
  return (
    <>
      <section>
        <Spacer height="90px" />
        <Spacer height="40px" clasName="col-12" />
        <Container>
          <Row>
            <Placeholder
              as={"h1"}
              animation="glow"
              className={css.listagem_titulo}
            >
              <Placeholder xs={4} lg={2} />
            </Placeholder>

            <Placeholder
              as={"h1"}
              animation="glow"
              className={`${css.listagem_subtitulo} titulo-page`}
            >
              <Placeholder xs={5} lg={3} />
            </Placeholder>
          </Row>
        </Container>
        <Spacer height="150px" clasName="col-12" />
      </section>
    </>
  );
}
