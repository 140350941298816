import React from "react";
import bandeiras1 from "../../../../assets/icons/bandeiras/PT.webp";
import bandeiras2 from "../../../../assets/icons/bandeiras/FR.webp";
import bandeiras3 from "../../../../assets/icons/bandeiras/GB.webp";
import bandeiras4 from "../../../../assets/icons/bandeiras/DE.webp";
import css from "./menuMobile.module.css";

export default function MenuTradutor() {
  const changeEvent = (el) => {
    if (el.fireEvent) {
      el.fireEvent("onchange");
    } else {
      var evObj = document.createEvent("HTMLEvents");

      evObj.initEvent("change", false, true);
      el.dispatchEvent(evObj);
    }
  };

  const trocarIdioma = (sigla) => {
    const comboGoogleTradutor = document
      .getElementById("google_translate_element")
      .querySelector(".goog-te-combo:first-child");
    if (comboGoogleTradutor) {
      comboGoogleTradutor.value = sigla;
      changeEvent(comboGoogleTradutor); //Dispara a troca
    }
  };
  return (
    <div className="col p-3">
      <div className={`${css.menuMobileTitulo} cor-titulo `}>
        Google Translate
      </div>
      <div className={`${css.menuMobileRedes} col nopadding `}>
        <div
          onClick={() => trocarIdioma("pt")}
          className={css.menuMobileRedesItem}
          style={{ cursor: "pointer" }}
        >
          <div className={`menu ${css.btn}`}>
            <img src={bandeiras1} alt="bandeiras1" />
          </div>
          português
        </div>
        <div className={css.with}></div>
        <div
          onClick={() => trocarIdioma("fr")}
          className={css.menuMobileRedesItem}
          style={{ cursor: "pointer" }}
        >
          <div className={`menu ${css.btn}`}>
            <img src={bandeiras2} alt="bandeiras2" />
          </div>
          francês
        </div>
        <div className={css.with}></div>
        <div
          onClick={() => trocarIdioma("en")}
          className={css.menuMobileRedesItem}
          style={{ cursor: "pointer" }}
        >
          <div className={`menu ${css.btn}`}>
            <img src={bandeiras3} alt="bandeiras3" />
          </div>
          Inglês
        </div>
        <div className={css.with}></div>
        <div
          onClick={() => trocarIdioma("de")}
          className={css.menuMobileRedesItem}
          style={{ cursor: "pointer" }}
        >
          <div className={`menu ${css.btn}`}>
            <img src={bandeiras4} alt="bandeiras4" />
          </div>
          Alemão
        </div>
      </div>
    </div>
  );
}
