import { Item } from "react-html-email";
import React from "react";

export const BodyFooter = (props) => (
  <Item style={props.styles.item}>
 
      <props.bodyFooterText />
 
  </Item>
);
