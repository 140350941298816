import { Select } from "react-functional-select";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { ReactComponent as Arrow } from "../../../../../assets/icons/pesquisa/select-seta.svg";
import { ReactComponent as Clear } from "../../../../../assets/icons/pesquisa/fechar-select.svg";
import { ButtonMuiltiSelect } from "../../../../helpers/tratarInfo";
import css from "../pesquisa.module.css";

export default function SegmentoSelect({
  Array,
  onChange,
  UpdateSelectRef,
  ArraySelect,
  infoJson,
}) {
  const selectRef = useRef();
  const [option, setOption] = useState([]);
  const getOptionValue = useCallback((option) => option.Tipo.Id, []);
  const getOptionLabel = useCallback((option) => option.Tipo.Nome, []);

  const onOptionChange = (option) => {
    const item = option.map((i) => {
      return i.Tipo.Id;
    });
    const selectedInt = item.map((i) => Number(i));

    onChange(selectedInt);
  };

  const renderMultiOptions = useCallback(
    ({ selected }) => (
      <div>
        {selected.length > 0 &&
          `${selected.length} ${
            selected.length === 1 ? "selecionado" : "selecionados"
          }`}
      </div>
    ),
    []
  );

  useEffect(() => {
    selectRef.current?.setValue(ArraySelect);
  }, [ArraySelect]);

  useEffect(() => {
    const allListaPorVender = Array.filter((carro) => {
      return carro.Vendido === false;
    });
    const item = allListaPorVender
      .map(({ Tipo }) => {
        return {
          Tipo,
        };
      })
      .sort(function (a, b) {
        if (a.Tipo.Nome < b.Tipo.Nome) {
          return -1;
        }
        if (a.Tipo.Nome > b.Tipo.Nome) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item.Tipo.Id === carro.Tipo.Id)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);
    setOption(item);
    UpdateSelectRef(selectRef);
  }, [Array, UpdateSelectRef]);
  const clear = (
    <span>
      <Clear fill={infoJson.Cores.SVG} width="10px" />
    </span>
  );
  const arrow = (
    <span>
      <Arrow fill={infoJson.Cores.SVG} className={css.arrow} />
    </span>
  );

  return (
    <Select
      ref={selectRef}
      isMulti
      isClearable
      isSearchable={false}
      options={option}
      initialValue={ArraySelect}
      placeholder="Selecionar"
      onOptionChange={onOptionChange}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      openMenuOnClick={true}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      renderMultiOptions={renderMultiOptions}
      noOptionsMsg="Não existe"
      clearIcon={clear}
      caretIcon={arrow}
      themeConfig={ButtonMuiltiSelect(infoJson)}
    />
  );
}
