import React from "react";
import { Col, Container, Placeholder, Row } from "react-bootstrap";
import Spacer from "../../home/Spacer";
import css from "../carroFicha.module.css";
import cssIT from "../infoTop.module.css";
import cssC from "../caracteristicas/caracteristicas.module.css";
import cssBF from "../barFixed/infoCar.module.css";
export default function PhFicha2({ infoJson }) {
  return (
    <>
      <Spacer height="50px" clasName="d-md-block d-none" />
      <section>
        <Container fluid="xxxl">
          <Row className="mb-3">
            <Col xs={12} md={10} lg={8}>
              <Row className="align-items-center">
                <Col xs={12} md={3}>
                  <Row>
                    <Col xs={12} className={`mb-2`}>
                      <Placeholder as="p" animation="glow">
                        <Placeholder xs={4} size="lg" />
                      </Placeholder>
                    </Col>
                    <Col xs={12}>
                      <Placeholder as="p" animation="glow">
                        <Placeholder xs={5} />
                      </Placeholder>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={5}
                  className={`d-flex ${css.barInfo}  ${cssBF.totalW}`}
                >
                  {[...Array(3)].map((x, i) => (
                    <React.Fragment key={i}>
                      <ul
                        className={`${cssC.fichaDados} ${
                          infoJson.Layout.Ficha.tipo === 1
                            ? ""
                            : cssC.fichaDados2
                        }`}
                      >
                        <Placeholder
                          as="li"
                          animation="glow"
                          style={{
                            height: "30px",
                          }}
                        >
                          <Placeholder
                            xs={12}
                            style={{
                              backgroundColor: infoJson.Cores.Principal,
                              width: "40px ",
                              height: "100%",
                            }}
                          />
                        </Placeholder>

                        <li className="w-100">
                          <ul className="ps-2">
                            <Placeholder as="li" animation="glow">
                              <Placeholder xs={4} />
                            </Placeholder>
                            <Placeholder as="li" animation="glow">
                              <Placeholder xs={6} />
                            </Placeholder>
                          </ul>
                        </li>
                      </ul>
                    </React.Fragment>
                  ))}
                </Col>
                <Col xs={12} md={4}>
                  <Col xs={12} className={`mb-2`}>
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={1} size="xs" />
                    </Placeholder>
                  </Col>

                  <Col xs={12} className={`boxPrecoFicha  `}>
                    <Placeholder as="p" animation="glow">
                      <Placeholder
                        xs={4}
                        style={{
                          height: "30px",
                        }}
                      />
                    </Placeholder>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} lg={2} className={`${css.btn}  `}>
              <Col xs={12}>
                <Placeholder as="div" animation="glow">
                  <Placeholder
                    className={`${cssIT.btn_ficha} btnPesquisa w-100`}
                    xs={6}
                  />
                </Placeholder>
              </Col>

              <Col xs={12}>
                <Placeholder as="div" animation="glow">
                  <Placeholder
                    className={`${cssIT.btn_ficha} btnPesquisa w-100`}
                    xs={6}
                  />
                </Placeholder>
              </Col>
            </Col>
            <Col xs={12} md={2} lg={1} className={`${css.btn}  `}>
              <Placeholder as="div" animation="glow">
                <Placeholder xs={7} />
              </Placeholder>
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid="xxxl" className="p-0">
        <Placeholder
          as="div"
          className={`w-100 image-gallery-slides `}
          animation="glow"
          style={{ height: "80vh " }}
        >
          <Placeholder xs={12} className={`w-100 image-gallery-slides h-100`} />
        </Placeholder>
      </Container>
      <Container fluid="xxxl">
        <Row>
          <div className={`listagem_info ${css.box}`}>
            <Col xs={12} className={` ${cssC.boxBottom}  d-flex flex-wrap`}>
              {[...Array(16)].map((x, i) => (
                <React.Fragment key={i}>
                  <ul
                    className={`${cssC.fichaDados} ${
                      infoJson.Layout.Ficha.tipo === 1 ? "" : cssC.fichaDados2
                    }`}
                  >
                    <Placeholder
                      as="li"
                      animation="glow"
                      style={{
                        height: "30px",
                      }}
                    >
                      <Placeholder
                        xs={12}
                        style={{
                          backgroundColor: infoJson.Cores.Principal,
                          width: "40px ",
                          height: "100%",
                        }}
                      />
                    </Placeholder>

                    <li className="w-100">
                      <ul className="ps-2">
                        <Placeholder as="li" animation="glow">
                          <Placeholder xs={4} />
                        </Placeholder>
                        <Placeholder as="li" animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                      </ul>
                    </li>
                  </ul>
                </React.Fragment>
              ))}
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
}
