"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactSEOMetaTags = void 0;
var React = require("react");
var SchemaOrg_1 = require("./SchemaOrg");
/**
 * Compact and comprehensive SEO meta tag component for general web pages and blog articles.
 * Includes basic meta tags as well as Facebook and Twitter tags and JSON-LD schema.
 * Originally designed for a Gatsby blog. Can be used in conjunction with react-helmet.
 */
var ReactSEOMetaTags = /** @class */ (function (_super) {
    __extends(ReactSEOMetaTags, _super);
    function ReactSEOMetaTags() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * General tags. OG and Twitter tags both fallback on these three incase description or image is not provided.
     * However by the way this library works, OG and Twitter tags are both extended from the same objects so
     * they'll always be defined or undefined.
     * @param props
     */
    ReactSEOMetaTags.prototype.renderGeneral = function (_a) {
        var title = _a.title, description = _a.description, image = _a.image;
        return ([
            React.createElement("title", { key: "title" }, title),
            description && React.createElement("meta", { key: "description", name: "description", content: description }),
            image && React.createElement("meta", { key: "image", name: "image", content: image })
        ]);
    };
    ReactSEOMetaTags.prototype.renderNonBlogOgTags = function () {
        return ([
            React.createElement("meta", { key: "og:type", property: "og:type", content: "website" }),
        ]);
    };
    ReactSEOMetaTags.prototype.renderBlogOgTags = function (_a) {
        var datePublished = _a.datePublished, dateModified = _a.dateModified;
        return ([
            React.createElement("meta", { key: "og:type", property: "og:type", content: "article" }),
            datePublished && React.createElement("meta", { key: "article:published_time", property: "article:published_time", content: datePublished }),
            dateModified && React.createElement("meta", { key: "article:modified_time", property: "article:modified_time", content: dateModified }),
        ]);
    };
    /**
     * https://developers.facebook.com/docs/sharing/webmasters/
     * http://ogp.me/
     * @param props
     */
    ReactSEOMetaTags.prototype.renderFacebook = function (props) {
        var url = props.url, title = props.title, description = props.description, image = props.image, imageAlt = props.imageAlt, video = props.video, audio = props.audio, site = props.site, _a = props.language, language = _a === void 0 ? 'en-US' : _a, facebookAppId = props.facebookAppId;
        return ([
            url && React.createElement("meta", { key: "og:url", property: "og:url", content: url }),
            React.createElement("meta", { property: "og:locale", content: language }),
            React.createElement("meta", { key: "og:title", property: "og:title", content: title }),
            description && React.createElement("meta", { key: "og:description", property: "og:description", content: description }),
            // Facebook recommends 1200x630 size, ratio of 1.91:1 but 1200x1200 is also fine
            image && React.createElement("meta", { key: "og:image", property: "og:image", content: image }),
            imageAlt && React.createElement("meta", { key: "og:image:alt", property: "og:image:alt", content: imageAlt }),
            video && React.createElement("meta", { key: "og:video", property: "og:video", content: video }),
            audio && React.createElement("meta", { key: "og:audio", property: "og:audio", content: audio }),
            site && site.siteName && React.createElement("meta", { key: "og:site_name", property: "og:site_name", content: site.siteName }),
            facebookAppId && React.createElement("meta", { key: "fb:app_id", property: "fb:app_id", content: facebookAppId })
        ]);
    };
    ReactSEOMetaTags.prototype.renderTwitter = function (props) {
        var title = props.title, description = props.description, image = props.image, imageAlt = props.imageAlt, _a = props.cardType, cardType = _a === void 0 ? 'summary_large_image' : _a, twitterUser = props.twitterUser, twitterSite = props.twitterSite;
        return ([
            image && React.createElement("meta", { key: "twitter:card", name: "twitter:card", content: cardType }),
            twitterUser && React.createElement("meta", { key: "twitter:creator", name: "twitter:creator", content: twitterUser }),
            twitterSite && React.createElement("meta", { key: "twitter:site", name: "twitter:site", content: twitterSite }),
            React.createElement("meta", { key: "twitter:title", name: "twitter:title", content: title }),
            description && React.createElement("meta", { key: "twitter:description", name: "twitter:description", content: description }),
            image && React.createElement("meta", { key: "twitter:image", name: "twitter:image", content: image }),
            imageAlt && React.createElement("meta", { key: "twitter:image:alt", name: "twitter:image:alt", content: imageAlt })
        ]);
    };
    ReactSEOMetaTags.prototype.renderBlogPostSEO = function (props) {
        var website = props.website, breadcrumb = props.breadcrumb, facebook = props.facebook, twitter = props.twitter, organization = props.organization;
        var blogPost = props.blogPost;
        return ([
            this.renderGeneral(__assign(__assign({}, website), blogPost)),
            React.createElement("script", { key: "application/ld+json", type: "application/ld+json" }, JSON.stringify([
                SchemaOrg_1.generateWebsite(blogPost),
                breadcrumb && SchemaOrg_1.generateBreadcrumbList(breadcrumb),
                SchemaOrg_1.generateBlogPosting(blogPost),
                organization && SchemaOrg_1.generateOrganization(organization),
            ].filter(function (obj) { return obj !== undefined && obj !== null; }))),
            this.renderBlogOgTags(blogPost),
            this.renderFacebook(__assign(__assign(__assign({}, website), blogPost), facebook)),
            this.renderTwitter(__assign(__assign(__assign({}, website), blogPost), twitter))
        ]);
    };
    ReactSEOMetaTags.prototype.renderWebsiteSEO = function (props) {
        var facebook = props.facebook, breadcrumb = props.breadcrumb, twitter = props.twitter, organization = props.organization;
        var website = props.website;
        return ([
            this.renderGeneral(website),
            React.createElement("script", { key: "application/ld+json", type: "application/ld+json" }, 
            /**
             * Stringifying eliminates the undefined values, which keeps the JSON-LD somewhat tidy.
             * Some empty objects might remain, but that shouldn't be a problem.
             */
            JSON.stringify([
                SchemaOrg_1.generateWebsite(website),
                breadcrumb && SchemaOrg_1.generateBreadcrumbList(breadcrumb),
                organization && SchemaOrg_1.generateOrganization(organization),
            ].filter(function (obj) { return obj !== undefined && obj !== null; }))),
            this.renderNonBlogOgTags(),
            this.renderFacebook(__assign(__assign({}, website), facebook)),
            this.renderTwitter(__assign(__assign({}, website), twitter))
        ]);
    };
    ReactSEOMetaTags.prototype.render = function () {
        var el;
        if (this.props.blogPost) {
            el = this.renderBlogPostSEO(this.props);
        }
        else if (this.props.website) {
            el = this.renderWebsiteSEO(this.props);
        }
        if (this.props.render) {
            return this.props.render(el);
        }
        return el;
    };
    return ReactSEOMetaTags;
}(React.PureComponent));
exports.ReactSEOMetaTags = ReactSEOMetaTags;
