import React from "react";
import css from "./dot.module.css";

export default function DotContacto({ link, children, ...props }) {
  return (
    <a href={link} {...props}>
      <div className={`${css.box}`}>{children}</div>
    </a>
  );
}
