import axios from "axios";

const global = require("../config/config");



async function getListaMotas(infoJson) {
  try {
    var API_URL_Lista_Motas = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/motas/GetListaMotas/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: infoJson.Stand.Token },
    };
  
    const res = await axios.request(API_URL_Lista_Motas);
  
    if (res.data.length > 0) {
      const carros = res.data.map((motas) => {
        return { ...motas, Viatura: "motas" };
      });
      return carros;
    }
    return [];
  } catch (error) {
    console.error('error', error)
  }

}

export { getListaMotas };
