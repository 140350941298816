import React, { useEffect, useState } from "react";
import css from "./header.module.css";
import { ReactComponent as Hand } from "../../../assets/icons/easybuy/hand.svg";
import useMediaQuery from "../../hooks/useMediaQuery ";

export default function EasyBuy({ infoJson }) {

  const [device, setDevice] = useState("desktop");

  let dark = "";
  infoJson.Layout.ThemeDark ? (dark = "-dark") : (dark = "");
 
  const isDesktop = useMediaQuery("(max-width: 991)");
  useEffect(() => {
    isDesktop ? setDevice("desktop") : setDevice("mobile");
  }, [isDesktop]);

  return (
    <div
      className={css.mySidenaver}
      style={{
        backgroundImage: `url(${require(`../../../assets/icons/easybuy/bg-compramos-${device}${dark}.png`)})`,
      }}
    >
      <a
        id="compramos1"
        href={infoJson.Compramos.easybuy.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className={css.mySidenaverContent}>
          <span className={css.content}>
            <b>COMPRAMOS</b>
            <br></br>O SEU AUTOMÓVEL
          </span>
          <Hand className={css.iconCompramosWidget} fill={infoJson.Cores.Principal} />
        </div>
      </a>
    </div>
  );
}
