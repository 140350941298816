import React from "react";
import css from "./menuMobile.module.css";

export default function InfoMenu({ link, children, target, rel }) {
  return (
    <a href={link} target={target} rel={rel}>
      <div className={css.menuMobileRedesItem}>{children}</div>
    </a>
  );
}
