import { Select } from "react-functional-select";
import React, { useCallback, useEffect, useState } from "react";
import { ButtonMuiltiSelectRapida } from "../../../../helpers/tratarInfo";

export default function RapidoSelect({
  Array,
  onChange,
  infoJson,
  ArraySelect,
}) {
  const [option, setOption] = useState([]);
  const getOptionValue = useCallback((option) => option.Codigo, []);
  const getOptionLabel = useCallback((option) => option.Nome, []);

  const onOptionChange = (option) => {
    const item = option.map((i) => {
      return i.Codigo;
    });
    onChange(item);
  };

  useEffect(() => {
    setOption(Array);
  }, [Array]);

  const arrow = <></>;

  return (
    <>
      <Select
        isMulti
        isClearable={false}
        initialValue={ArraySelect}
        isSearchable
        options={option}
        placeholder="SUV a gasóleo de 7 lugares"
        onOptionChange={onOptionChange}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        openMenuOnClick={true}
        blurInputOnSelect={false}
        // closeMenuOnSelect={false}
        hideSelectedOptions={true}
        filterIgnoreCase={true}
        filterIgnoreAccents={true}
        noOptionsMsg="Não existe"
        caretIcon={arrow}
        themeConfig={ButtonMuiltiSelectRapida(infoJson)}
      />
    </>
  );
}
