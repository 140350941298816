import React, { useEffect, useState } from "react";
import * as apiEmpresa from "../../../../api/apiEmpresa";
import css from "./banners.module.css";
import Carousel from "react-bootstrap/Carousel";
import useMediaQuery from "../../../hooks/useMediaQuery ";
import ImageLoad from "../../../hooks/ImageLoad";

export default function Banners({ infoJson }) {
  const [BannerDesktop, setBannerDesktop] = useState([]);
  const [BannerMobile, setBannerMobile] = useState([]);
  const [Banner, setBanner] = useState([]);
  const [rows, setRows] = useState([]);

  const isMobile = useMediaQuery("(max-width: 991px)");

  useEffect(() => {
    const getBanner = async () => {
      const bannersDesktop = await apiEmpresa.getBanner(
        infoJson.Banner.Desktop,
        infoJson
      );

      setBannerDesktop(bannersDesktop);
      const bannersMobile = await apiEmpresa.getBanner(
        infoJson.Banner.Mobile,
        infoJson
      );
      setBannerMobile(bannersMobile);
      isMobile ? setBanner(bannersMobile) : setBanner(bannersDesktop);
    };
    getBanner();
  }, [isMobile, infoJson]);

  useEffect(() => {
    isMobile ? setBanner(BannerMobile) : setBanner(BannerDesktop);
  }, [isMobile, BannerMobile, BannerDesktop]);

  useEffect(() => {
    const items = [];
    for (let index = 1; index <= infoJson.Banner.Numero; index++) {
      let imagem_selo;
      isMobile
        ? (imagem_selo = `${`/assets/img/header_mobile${index}.webp`}`)
        : (imagem_selo = `${`/assets/img/header_desktop${index}.webp`}`);
      items.push(
        <Carousel.Item key={index}>
          <div className={`${css.banner}`}>
            <img src={imagem_selo} alt={`banner${index}`} />
          </div>
        </Carousel.Item>
      );
    }
    setRows(items);
  }, [isMobile, infoJson]);

  return (
    <Carousel fade={true} controls={false} interval={3000} indicators={false}>
      {Banner.length !== 0 &&
      Banner.length !== undefined &&
      Banner.length !== null
        ? Banner.map((banner, index) => {
            const { Ficheiro, Target, Url, CodBanner } = banner;

            return (
              <Carousel.Item key={CodBanner}>
                <div className={`${css.banner}`}>
                  {Url !== "" && Target !== "" ? (
                    <a href={Url} target={Target} rel="noreferrer">
                      <ImageLoad
                        src={Ficheiro}
                        placeholder={Ficheiro}
                        alt={`banner${index}`}
                      />
                    </a>
                  ) : (
                    <ImageLoad
                      src={Ficheiro}
                      placeholder={Ficheiro}
                      alt={`banner${index}`}
                    />
                  )}
                </div>
              </Carousel.Item>
            );
          })
        : rows}
    </Carousel>
  );
}
