import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import css from "../carrousel.module.css";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow/Slider.svg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useMediaQuery from "../../../hooks/useMediaQuery ";
import CardMarca from "./CardMarca";

export default function CarrouselMarcas({ allLista, infoJson }) {
  const ismobile = useMediaQuery("(max-width: 574px)");
  const [marcas, setMarcas] = useState([]);

  useEffect(() => {
    const allListaPorVender = allLista.filter((carro) => {
      return carro.Vendido === false;
    });
    const item = allListaPorVender
      .map(({ Marca }) => {
        return {
          Marca,
        };
      })
      .sort(function (a, b) {
        if (a.Marca.Nome < b.Marca.Nome) {
          return -1;
        }
        if (a.Marca.Nome > b.Marca.Nome) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);
    setMarcas(item);
  }, [allLista, infoJson.Layout.Vendidos.top]);

  const responsive = {
    0: {
      slidesPerView: 2.3,
    },
    375: {
      slidesPerView: 3.3,
    },
    574: {
      slidesPerView: 4,
    },
    767: {
      slidesPerView: 5,
    },
    991: {
      slidesPerView: 5,
    },
    1199: {
      slidesPerView: 6,
    },
    1399: {
      slidesPerView: 7,
    },
    1600: {
      slidesPerView: 8,
    },
   
  };

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);



  return (
    <>
      <Swiper
        className={"recentes-slider"}
        breakpoints={responsive}
        // autoplay={ismobile ? false : true}
        pagination={false}
        navigation={
          ismobile
            ? false
            : {
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
                disabledClass: css.buttonDisable,
                lockClass: css.buttonLock,
              }
        }
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        effect={"slide"}
        centerInsufficientSlides={true}
        centeredSlides={false}
        loop={false}
        speed={500}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {marcas.map((carro) => {
          return (
            <SwiperSlide key={carro.Marca.Id}>
              <CardMarca data={carro} infoJson={infoJson} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {ismobile ? (
        <></>
      ) : (
        <>
          <div
            className={`${css.buttonPrev} ${css.buttonPrevR}`}
            ref={navigationPrevRef}
          >
            <Arrow
              height="60px"
              style={{ transform: `rotate(180deg)` }}
              stroke={infoJson.Cores.ArrowSlider}
            />
          </div>
          <div
            className={`${css.buttonNext} ${css.buttonNextR}`}
            ref={navigationNextRef}
          >
            <Arrow height="60px" stroke={infoJson.Cores.ArrowSlider} />
          </div>
        </>
      )}
    </>
  );
}
