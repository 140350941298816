import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Form, Row } from "react-bootstrap";
import css from "../pesquisa.module.css";
import Marca from "./Marca";
import Modelos from "./Modelos";
import Transmissao from "./Transmissao";
import Combustivel from "./Combustivel";
import Segmento from "./Segmento";
import Extras from "./Extras";
import MarcaSelect from "./MarcaSelect";
import ModelosSelect from "./ModelosSelect";
import CombustivelSelect from "./CombustivelSelect";
import SegmentoSelect from "./SegmentoSelect";
import TransmissaoSelect from "./TransmissaoSelect";
import ExtrasSelect from "./ExtrasSelect";
import Lotacao from "./Lotacao";
import LotacaoSelect from "./LotacaoSelect";
import pesquisa from "../../../../../assets/icons/pesquisa/pesquisa.svg";
import {
  createUrl,
  NumeroAtualizarPesquisa,
} from "../../../../helpers/tratarInfoCarros";
import * as apiDados from "../../../../../api/apiDados";
import AnoSlider from "./AnoSlider";
import PrecoSlider from "./PrecoSlider";
import KmSlider from "./KmSlider";
import PotenciaSlider from "./PotenciaSlider";
import { ReactComponent as Limpar } from "../../../../../assets/icons/pesquisa/limpar.svg";
import Spacer from "../../Spacer";
import useVerificaUrlPesquisa from "../../../../hooks/useVerificaUrlPesquisa";
const transmissao = [
  { CodTransmissao: 2, Transmissao: "Automática" },
  { CodTransmissao: 1, Transmissao: "Manual" },
];

export default function FormPesquisaAvancada({
  ArrayCarros,
  url,
  tipo,
  setFilteredCarros,
  infoJson,
  tipoViatura,
  allLista,
}) {
  const navigate = useNavigate();
  const [NumeroResultados, setNumeroResultados] = useState([0]);
  const [selectRefMarca, setSelectRefMarca] = useState([]);
  const [selectRefModelo, setSelectRefModelo] = useState([]);
  const [selectRefCombustivel, setSelectRefCombustivel] = useState([]);
  const [selectRefTransmissao, setSelectRefTransmissao] = useState([]);
  const [selectRefSegmento, setSelectRefSegmento] = useState([]);
  const [selectRefExtras, setSelectRefExtras] = useState([]);
  const [modelos, setModelos] = useState([]);

  const [PesquisaMarcas, setPesquisaMarcas] = useState([]);
  const [PesquisaMarcasSelect, setPesquisaMarcasSelect] = useState([]);
  const [PesquisaModelos, setPesquisaModelos] = useState([]);
  const [PesquisaModelosSelect, setPesquisaModelosSelect] = useState([]);
  const [PesquisaTransmissao, setPesquisaTransmissao] = useState([]);
  const [PesquisaTransmissaoSelect, setPesquisaTransmissaoSelect] = useState(
    []
  );
  const [PesquisaLotacao, setPesquisaLotacao] = useState([]);
  const [PesquisaLotacaoSelect, setPesquisaLotacaoSelect] = useState([]);
  const [selectRefLotacao, setSelectRefLotacao] = useState([]);
  const [PesquisaCombustivel, setPesquisaCombustivel] = useState([]);
  const [PesquisaCombustivelSelect, setPesquisaCombustivelSelect] = useState(
    []
  );
  const [PesquisaSegmento, setPesquisaSegmento] = useState([]);
  const [PesquisaSegmentoSelect, setPesquisaSegmentoSelect] = useState([]);
  const [PesquisaExtras, setPesquisaExtras] = useState([]);
  const [PesquisaAnoInicial, setPesquisaAnoInicial] = useState("");
  const [PesquisaAnoFinal, setPesquisaAnoFinal] = useState("");
  const [PesquisaKmInicial, setPesquisaKmInicial] = useState("");
  const [PesquisaKmFinal, setPesquisaKmFinal] = useState("");
  const [PesquisaPrecoInicial, setPesquisaPrecoInicial] = useState("");
  const [PesquisaPrecoFinal, setPesquisaPrecoFinal] = useState("");
  const [PesquisaPotenciaInicial, setPesquisaPotenciaInicial] = useState("");
  useEffect(() => {
    setNumeroResultados(ArrayCarros.length);
  }, [ArrayCarros]);

  useVerificaUrlPesquisa(
    ArrayCarros,
    tipo,
    url,
    setFilteredCarros,
    setPesquisaMarcas,
    setPesquisaMarcasSelect,
    setModelos,
    setPesquisaModelos,
    setPesquisaModelosSelect,
    setPesquisaCombustivel,
    setPesquisaCombustivelSelect,
    setPesquisaTransmissao,
    setPesquisaTransmissaoSelect,
    setPesquisaSegmento,
    setPesquisaSegmentoSelect,
    setPesquisaExtras,
    setPesquisaAnoInicial,
    setPesquisaAnoFinal,
    setPesquisaKmInicial,
    setPesquisaKmFinal,
    setPesquisaPrecoInicial,
    setPesquisaPrecoFinal,
    setPesquisaPotenciaInicial,
    setPesquisaLotacao,
    setPesquisaLotacaoSelect,
    infoJson,
    allLista
  );

  const onChangeMarcas = (marcas, modelos) => {
    setPesquisaMarcas(marcas);
    setModelos(modelos);
  };

  const clearValueMarca = () => {
    setPesquisaMarcas([]);
    setPesquisaModelos([]);
    setPesquisaMarcasSelect([]);
    setPesquisaModelosSelect([]);
    selectRefMarca.current?.clearValue();
    selectRefMarca.current?.clearValue();
  };

  const clearValueModelo = () => selectRefModelo.current?.clearValue();
  const clearValueLotacao = () => selectRefLotacao.current?.clearValue();
  const clearValueCombustivel = () =>
    selectRefCombustivel.current?.clearValue();
  const clearValueTransmissao = () =>
    selectRefTransmissao.current?.clearValue();
  const clearValueSegmento = () => selectRefSegmento.current?.clearValue();
  const clearValueExtras = () => selectRefExtras.current?.clearValue();

  const clearAll = () => {
    setPesquisaAnoInicial("");
    setPesquisaAnoFinal("");
    setPesquisaKmInicial("");
    setPesquisaKmFinal("");
    setPesquisaPrecoInicial("");
    setPesquisaPrecoFinal("");
    setPesquisaPotenciaInicial("");
    clearValueMarca();
    clearValueModelo();
    clearValueCombustivel();
    clearValueTransmissao();
    clearValueSegmento();
    clearValueExtras();
    clearValueLotacao();
    setNumeroResultados(ArrayCarros.length);
  };

  const handleSubmit = (e) => {
    let params = new URLSearchParams();
    // verifica se existe parametros na query

    PesquisaCombustivel.length !== 0 &&
      params.append("Combustivel", PesquisaCombustivel);
    PesquisaSegmento.length !== 0 &&
      params.append("Segmento", PesquisaSegmento);
    PesquisaLotacao.length !== 0 && params.append("Lotacao", PesquisaLotacao);
    PesquisaTransmissao.length !== 0 &&
      params.append("Transmissao", PesquisaTransmissao);
    PesquisaExtras.length !== 0 && params.append("Extras", PesquisaExtras);
    PesquisaAnoInicial !== "" &&
      params.append("AnoInicial", PesquisaAnoInicial);
    PesquisaAnoFinal !== "" && params.append("AnoFinal", PesquisaAnoFinal);
    PesquisaPrecoInicial !== "" &&
      params.append("PrecoInicial", PesquisaPrecoInicial);
    PesquisaPrecoFinal !== "" &&
      params.append("PrecoFinal", PesquisaPrecoFinal);
    PesquisaKmInicial !== "" && params.append("KmInicial", PesquisaKmInicial);
    PesquisaKmFinal !== "" && params.append("KmFinal", PesquisaKmFinal);
    PesquisaPotenciaInicial !== "" &&
      params.append("PotenciaInicial", PesquisaPotenciaInicial);
    e.preventDefault();
    if (tipo === url ? url : "/") {
      const path = `/${tipo}/${
        PesquisaMarcas.length !== 0
          ? `${createUrl(ArrayCarros, "marca", PesquisaMarcas)}/`
          : ""
      }${
        PesquisaModelos.length !== 0
          ? `${createUrl(ArrayCarros, "modelo", PesquisaModelos)}/`
          : ""
      }${params.toString() !== "" ? `?${params.toString()}` : ""}`;

      navigate(
        `${path}${infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""}`,
        { replace: true, state: { goToListagem: true } }
      );

      if (
        PesquisaMarcas.length !== 0 ||
        PesquisaModelos.length !== 0 ||
        PesquisaCombustivel.length !== 0 ||
        PesquisaSegmento.length !== 0 ||
        PesquisaTransmissao.length !== 0 ||
        PesquisaExtras.length !== 0 ||
        PesquisaAnoInicial !== "" ||
        PesquisaAnoFinal !== "" ||
        PesquisaPrecoInicial !== "" ||
        PesquisaPrecoFinal !== "" ||
        PesquisaKmInicial !== "" ||
        PesquisaKmFinal !== "" ||
        PesquisaPotenciaInicial !== ""
      ) {
        apiDados.InsertDados(
          PesquisaMarcas,
          PesquisaModelos,
          PesquisaCombustivel,
          PesquisaSegmento,
          PesquisaTransmissao,
          PesquisaExtras,
          PesquisaAnoInicial,
          PesquisaAnoFinal,
          PesquisaPrecoInicial,
          PesquisaPrecoFinal,
          PesquisaKmInicial,
          PesquisaKmFinal,
          PesquisaPotenciaInicial,
          tipoViatura,
          infoJson
        );
      }
    } else {
      const path = `/${tipo}/${
        PesquisaMarcas.length !== 0
          ? `${createUrl(ArrayCarros, "marca", PesquisaMarcas)}/`
          : ""
      }${
        PesquisaModelos.length !== 0
          ? `${createUrl(ArrayCarros, "modelo", PesquisaModelos)}/`
          : ""
      }${params.toString() !== "" ? `?${params.toString()}` : ""}`;

      navigate(
        `${path}${infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""}`,
        { replace: true }
      );
    }
  };

  useEffect(() => {
    const valor = NumeroAtualizarPesquisa(
      PesquisaMarcas,
      PesquisaModelos,
      PesquisaCombustivel,
      PesquisaSegmento,
      PesquisaTransmissao,
      PesquisaExtras,
      PesquisaAnoInicial,
      PesquisaAnoFinal,
      PesquisaPrecoInicial,
      PesquisaPrecoFinal,
      PesquisaKmInicial,
      PesquisaKmFinal,
      PesquisaPotenciaInicial,
      PesquisaLotacao,
      ArrayCarros
    );
    setNumeroResultados(valor);
  }, [
    PesquisaMarcas,
    PesquisaModelos,
    PesquisaCombustivel,
    PesquisaSegmento,
    PesquisaTransmissao,
    PesquisaExtras,
    PesquisaAnoInicial,
    PesquisaAnoFinal,
    PesquisaPrecoInicial,
    PesquisaPrecoFinal,
    PesquisaKmInicial,
    PesquisaKmFinal,
    PesquisaPotenciaInicial,
    PesquisaLotacao,
    ArrayCarros,
  ]);

  return (
    <>
      {ArrayCarros.filter((item) => item.Vendido === false).length !== 0 && (
        <Form onSubmit={handleSubmit} action={tipo}>
          <Col xs={12}>
            <Form.Group xs={12} as={Col}>
              <Row>
                <Col xs={6} lg={3}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Marca </label>
                    {ArrayCarros.length !== 0 &&
                    PesquisaMarcasSelect.length !== 0 ? (
                      <MarcaSelect
                        UpdateSelectRef={setSelectRefMarca}
                        Array={ArrayCarros}
                        onChange={onChangeMarcas}
                        ArraySelect={PesquisaMarcasSelect}
                        infoJson={infoJson}
                      />
                    ) : (
                      <Marca
                        UpdateSelectRef={setSelectRefMarca}
                        Array={ArrayCarros}
                        onChange={onChangeMarcas}
                        infoJson={infoJson}
                        ArraySelect={PesquisaMarcasSelect}
                      />
                    )}
                  </Col>
                </Col>
                <Col xs={6} lg={3}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Modelo </label>
                    {modelos.length === 0 &&
                    PesquisaModelosSelect.length === 0 ? (
                      <div className={`${css.btnPesquisaModelo} selectSvg`}>
                        Selecionar
                      </div>
                    ) : modelos.length !== 0 &&
                      PesquisaModelosSelect.length !== 0 ? (
                      <ModelosSelect
                        Array={modelos}
                        onChange={setPesquisaModelos}
                        ArraySelect={PesquisaModelosSelect}
                        UpdateSelectRef={setSelectRefModelo}
                        infoJson={infoJson}
                      />
                    ) : (
                      <Modelos
                        Array={modelos}
                        onChange={setPesquisaModelos}
                        UpdateSelectRef={setSelectRefModelo}
                        infoJson={infoJson}
                      />
                    )}
                  </Col>
                </Col>
                <Col xs={12} lg={6}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} ${css.boxShadowParesRange} boxShadowPares mb-3`}
                  >
                    <label>intervalo de Preço </label>
                    <PrecoSlider
                      ArrayCarros={ArrayCarros}
                      PesquisaPrecoInicial={PesquisaPrecoInicial}
                      PesquisaPrecoFinal={PesquisaPrecoFinal}
                      UpdateInicial={setPesquisaPrecoInicial}
                      UpdateFinal={setPesquisaPrecoFinal}
                      infoJson={infoJson}
                    />
                  </Col>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group xs={12} as={Col}>
              <Row>
                <Col xs={12} lg={6}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} ${css.boxShadowParesRange} boxShadowPares mb-3`}
                  >
                    <label>Ano </label>
                    <AnoSlider
                      PesquisaAnoInicial={PesquisaAnoInicial}
                      PesquisaAnoFinal={PesquisaAnoFinal}
                      ArrayCarros={ArrayCarros}
                      UpdateInicial={setPesquisaAnoInicial}
                      UpdateFinal={setPesquisaAnoFinal}
                      infoJson={infoJson}
                    />
                  </Col>
                </Col>
                <Col xs={12} lg={6}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} ${css.boxShadowParesRange} boxShadowPares mb-3`}
                  >
                    <label>Quilómetros </label>
                    <KmSlider
                      PesquisaKmInicial={PesquisaKmInicial}
                      PesquisaKmFinal={PesquisaKmFinal}
                      ArrayCarros={ArrayCarros}
                      UpdateInicial={setPesquisaKmInicial}
                      UpdateFinal={setPesquisaKmFinal}
                      infoJson={infoJson}
                    />
                  </Col>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group xs={12} as={Col}>
              <Row>
                <Col xs={12} lg={3}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} ${css.boxShadowParesRange} boxShadowPares mb-3`}
                  >
                    <label>Potência </label>
                    <PotenciaSlider
                      PesquisaPotenciaInicial={PesquisaPotenciaInicial}
                      ArrayCarros={ArrayCarros}
                      Update={setPesquisaPotenciaInicial}
                      infoJson={infoJson}
                    />
                  </Col>
                </Col>
                <Col xs={6} lg={3}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Transmissão </label>
                    {ArrayCarros.length !== 0 &&
                    PesquisaTransmissaoSelect.length !== 0 ? (
                      <TransmissaoSelect
                        UpdateSelectRef={setSelectRefTransmissao}
                        Array={transmissao}
                        onChange={setPesquisaTransmissao}
                        ArraySelect={PesquisaTransmissaoSelect}
                        infoJson={infoJson}
                      />
                    ) : (
                      <Transmissao
                        UpdateSelectRef={setSelectRefTransmissao}
                        Array={transmissao}
                        onChange={setPesquisaTransmissao}
                        infoJson={infoJson}
                      />
                    )}
                  </Col>
                </Col>
                <Col xs={6} lg={3}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Combustivel </label>
                    {ArrayCarros.length !== 0 &&
                    PesquisaCombustivelSelect.length !== 0 ? (
                      <CombustivelSelect
                        UpdateSelectRef={setSelectRefCombustivel}
                        Array={ArrayCarros}
                        onChange={setPesquisaCombustivel}
                        ArraySelect={PesquisaCombustivelSelect}
                        infoJson={infoJson}
                      />
                    ) : (
                      <Combustivel
                        UpdateSelectRef={setSelectRefCombustivel}
                        Array={ArrayCarros}
                        onChange={setPesquisaCombustivel}
                        infoJson={infoJson}
                      />
                    )}
                  </Col>
                </Col>
                <Col xs={12} lg={3}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Segmento </label>
                    {ArrayCarros.length !== 0 &&
                    PesquisaSegmentoSelect.length !== 0 ? (
                      <SegmentoSelect
                        UpdateSelectRef={setSelectRefSegmento}
                        Array={ArrayCarros}
                        onChange={setPesquisaSegmento}
                        ArraySelect={PesquisaSegmentoSelect}
                        infoJson={infoJson}
                      />
                    ) : (
                      <Segmento
                        UpdateSelectRef={setSelectRefSegmento}
                        Array={ArrayCarros}
                        onChange={setPesquisaSegmento}
                        infoJson={infoJson}
                      />
                    )}
                  </Col>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xs={6} lg={3}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Lotação </label>
                    {ArrayCarros.length !== 0 &&
                    PesquisaLotacaoSelect.length !== 0 ? (
                      <LotacaoSelect
                        UpdateSelectRef={setSelectRefLotacao}
                        Array={ArrayCarros}
                        onChange={setPesquisaLotacao}
                        ArraySelect={PesquisaLotacaoSelect}
                        infoJson={infoJson}
                      />
                    ) : (
                      <Lotacao
                        UpdateSelectRef={setSelectRefLotacao}
                        Array={ArrayCarros}
                        onChange={setPesquisaLotacao}
                        infoJson={infoJson}
                      />
                    )}
                  </Col>
                </Col>
                <Col xs={12} xl={9}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Extras </label>
                    {ArrayCarros.length !== 0 && PesquisaExtras.length !== 0 ? (
                      <ExtrasSelect
                        UpdateSelectRef={setSelectRefExtras}
                        Array={ArrayCarros}
                        onChange={setPesquisaExtras}
                        ArraySelect={PesquisaExtras}
                        infoJson={infoJson}
                      />
                    ) : (
                      <Extras
                        UpdateSelectRef={setSelectRefExtras}
                        Array={ArrayCarros}
                        onChange={setPesquisaExtras}
                        infoJson={infoJson}
                      />
                    )}
                  </Col>
                </Col>
              </Row>
            </Form.Group>

            <div className={css.btnPequisaBox}>
              <Col xs={12} className="text-center">
                <button
                  className={` btnPesquisa ${css.btnPequisa}`}
                  type="submit"
                >
                  {infoJson.Layout.Carros.NumeroCarros ? (
                    <>
                      Ver {NumeroResultados} resultados{" "}
                      <img
                        height={"18px"}
                        width={"18px"}
                        src={pesquisa}
                        style={{ marginLeft: "5px" }}
                        alt="pesquisar"
                      />
                    </>
                  ) : (
                    <>
                      Ver resultados{" "}
                      <img
                        height={"18px"}
                        width={"18px"}
                        src={pesquisa}
                        style={{ marginLeft: "5px" }}
                        alt="pesquisar"
                      />
                    </>
                  )}
                </button>
              </Col>
              <Col xs={12}>
                <div
                  onClick={clearAll}
                  className={`${css.textoLimpar} textoLimpar `}
                >
                  <Limpar /> Limpar Parâmetros
                </div>
              </Col>
            </div>
          </Col>
          <Spacer clasName="d-block" height="24px" />
        </Form>
      )}
    </>
  );
}
