import React from "react";
import { Col, Container, Placeholder, Row } from "react-bootstrap";
import Spacer from "../../home/Spacer";
import css from "../carroFicha.module.css";
import cssIT from "../infoTop.module.css";
import cssC from "../caracteristicas/caracteristicas.module.css";
export default function PhFicha({ infoJson }) {
  return (
    <>
      <Spacer height="106px" clasName="d-md-block d-none" />
      <section>
        <Container fluid="xxxl">
          <Row>
            <Col
              xs={12}
              lg={{
                span: 5,
                order: infoJson.Layout.Ficha.RTL ? 2 : 1,
              }}
            >
              <Placeholder
                as="div"
                className={`w-100 image-gallery-slides h-100`}
                animation="glow"
              >
                <Placeholder
                  xs={12}
                  className={`w-100 image-gallery-slides h-100`}
                />
              </Placeholder>
            </Col>
            <Col
              xs={12}
              lg={{
                span: 7,
                order: infoJson.Layout.Ficha.RTL ? 1 : 2,
              }}
            >
              <div className={`listagem_info ${css.box}`}>
                <Col xs={12} className={cssIT.boxTop}>
                  <Row>
                    <Col xs={12} md={5}>
                      <Row>
                        <Col xs={12} className={`mb-2`}>
                          <Placeholder as="p" animation="glow">
                            <Placeholder xs={4} size="lg" />
                          </Placeholder>
                        </Col>
                        <Col xs={12}>
                          <Placeholder as="p" animation="glow">
                            <Placeholder xs={5} />
                          </Placeholder>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} md={7}>
                      <Col xs={12} className={`mb-2`}>
                        <Placeholder as="p" animation="glow">
                          <Placeholder xs={1} size="xs" />
                        </Placeholder>
                      </Col>

                      <Col xs={12} className={`boxPrecoFicha  `}>
                        <Placeholder as="p" animation="glow">
                          <Placeholder
                            xs={4}
                            style={{
                              height: "30px",
                            }}
                          />
                        </Placeholder>
                      </Col>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  className={`${cssIT.heightBoxTop} heightBoxTop`}
                ></Col>
                <Col xs={12} className={` ${cssC.boxBottom}  d-flex flex-wrap`}>
                  {[...Array(16)].map((x, i) => (
                    <React.Fragment key={i}>
                      <ul className={`${cssC.fichaDados} `}>
                        <Placeholder as="li" animation="glow">
                          <Placeholder
                            xs={12}
                            style={{
                              backgroundColor: infoJson.Cores.Principal,
                              height: "36px",
                            }}
                          />
                        </Placeholder>

                        <li className="w-100">
                          <ul className="ps-2">
                            <Placeholder as="li" animation="glow">
                              <Placeholder xs={4} />
                            </Placeholder>
                            <Placeholder as="li" animation="glow">
                              <Placeholder xs={6} />
                            </Placeholder>
                          </ul>
                        </li>
                      </ul>
                    </React.Fragment>
                  ))}
                </Col>
                <Row
                  xs={12}
                  className={`align-items-center d-flex ${cssIT.moreInfoFicha} `}
                >
                  <Col xs={4}>
                    <Placeholder as="div" animation="glow">
                      <Placeholder
                        className={`${cssIT.btn_ficha} btnPesquisa w-100`}
                        xs={6}
                      />
                    </Placeholder>
                  </Col>

                  <Col xs={4}>
                    <Placeholder as="div" animation="glow">
                      <Placeholder
                        className={`${cssIT.btn_ficha} btn_ficha w-100`}
                        xs={6}
                      />
                    </Placeholder>
                  </Col>

                  <Col xs={4}>
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={7} />
                      <Placeholder xs={7} />
                    </Placeholder>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Spacer height="106px" />
    </>
  );
}
