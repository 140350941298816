import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const useGaTracker = (infoJson) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      Object.keys(infoJson).length !== 0 &&
      infoJson.Stand.GoogleAnalytics.existe
    ) {
      if (!window.location.href.includes("localhost")) {
        ReactGA.initialize(infoJson.Stand.GoogleAnalytics.apiKey);
      }
      setInitialized(true);
    }
  }, [infoJson]);

  useEffect(() => {
    if (
      Object.keys(infoJson).length !== 0 &&
      infoJson.Stand.GoogleAnalytics.existe
    ) {
      if (initialized) {
        ReactGA.pageview(location.pathname + location.search);
      }
    }
  }, [initialized, location, infoJson]);
};

export default useGaTracker;
