import React from "react";
import Spacer from "../Spacer";
import css from "./banners.module.css";

export default function VideoBanner({ infoJson }) {
  return (
    <>
      <Spacer height="85px" clasName="d-lg-none" />
      <div className={css.bannerVideo}>
        <iframe
          src={infoJson.Banner.Video.Src}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}
