import React from "react";
import { EmailBody } from "./primitives";
import { Email } from "react-html-email";

export const HtmlEmail = ({
  theme,
  title,
  emailHeaderHref,
  emailHeaderText,
  emailHeaderLogo,
  bodyHeaderText,
  bodyContentComponent,
  bodyFooterText,
}) => (
  <Email title={title} style={theme.email} lang="PT" cellPadding={50}>
    <EmailBody
      theme={theme}
      title={title}
      emailHeaderHref={emailHeaderHref}
      emailHeaderText={emailHeaderText}
      emailHeaderLogo={emailHeaderLogo}
      bodyHeaderText={bodyHeaderText}
      bodyContentComponent={bodyContentComponent}
      bodyFooterText={bodyFooterText}
    />
  </Email>
);

export const WithDebug = (props) => {
  const { debug, ...rest } = props;

  return (
    <>
      <HtmlEmail {...rest} />
      {/* <code>{debug && renderEmail(<HtmlEmail {...rest} />)}</code> */}
    </>
  );
};
