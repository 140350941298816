import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LimparToUrl } from "../../../helpers/tratarInfoCarros";
import css from "./marcas.module.css";

const selectImage = (id, name, infoJson) => {
  switch (id) {
    case 460:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Abarth.webp`)}
          alt={name}
        />
      );
    case 121:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Alfa.webp`)}
          alt={name}
        />
      );
    case 142:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Audi.webp`)}
          alt={name}
        />
      );
    case 380:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bedford.webp`)}
          alt={name}
        />
      );
    case 124:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bentley.webp`)}
          alt={name}
        />
      );
    case 125:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/BMW.webp`)}
          alt={name}
        />
      );
    case 285:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bugatti.webp`)}
          alt={name}
        />
      );
    case 127:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Chevrolet.webp`)}
          alt={name}
        />
      );
    case 128:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Chrysler.webp`)}
          alt={name}
        />
      );
    case 129:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Citroen.webp`)}
          alt={name}
        />
      );
    case 345:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Dacia.webp`)}
          alt={name}
        />
      );
    case 280:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Datsun.webp`)}
          alt={name}
        />
      );
    case 278:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Dodge.webp`)}
          alt={name}
        />
      );
    case 447:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/DS.webp`)}
          alt={name}
        />
      );
    case 143:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Ferrari.webp`)}
          alt={name}
        />
      );
    case 277:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Fiat.webp`)}
          alt={name}
        />
      );
    case 276:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Ford.webp`)}
          alt={name}
        />
      );
    case 274:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Honda.webp`)}
          alt={name}
        />
      );
    case 271:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Isuzu.webp`)}
          alt={name}
        />
      );
    case 319:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Iveco.webp`)}
          alt={name}
        />
      );
    case 270:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Jaguar.webp`)}
          alt={name}
        />
      );
    case 269:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Jeep.webp`)}
          alt={name}
        />
      );
    case 268:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Kia.webp`)}
          alt={name}
        />
      );
    case 267:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lamborghini.webp`)}
          alt={name}
        />
      );
    case 266:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lancia.webp`)}
          alt={name}
        />
      );
    case 265:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/LandRover.webp`)}
          alt={name}
        />
      );
    case 264:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lexus.webp`)}
          alt={name}
        />
      );
    case 144:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lotus.webp`)}
          alt={name}
        />
      );
    case 262:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Maserati.webp`)}
          alt={name}
        />
      );
    case 261:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Mazda.webp`)}
          alt={name}
        />
      );
    case 173:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/MercedesBenz.webp`)}
          alt={name}
        />
      );
    case 170:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/MINI.webp`)}
          alt={name}
        />
      );
    case 165:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Mitsubishi.webp`)}
          alt={name}
        />
      );
    case 160:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Nissan.webp`)}
          alt={name}
        />
      );
    case 157:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Opel.webp`)}
          alt={name}
        />
      );
    case 156:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Peugeot.webp`)}
          alt={name}
        />
      );
    case 141:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Porsche.webp`)}
          alt={name}
        />
      );
    case 153:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Renault.webp`)}
          alt={name}
        />
      );
    case 151:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Rover.webp`)}
          alt={name}
        />
      );
    case 139:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Seat.webp`)}
          alt={name}
        />
      );
    case 138:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Skoda.webp`)}
          alt={name}
        />
      );
    case 137:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Smart.webp`)}
          alt={name}
        />
      );
    case 149:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Subaru.webp`)}
          alt={name}
        />
      );
    case 148:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Suzuki.webp`)}
          alt={name}
        />
      );
    case 451:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Tesla.webp`)}
          alt={name}
        />
      );
    case 145:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Toyota.webp`)}
          alt={name}
        />
      );
    case 135:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/UMM.webp`)}
          alt={name}
        />
      );
    case 133:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Volvo.webp`)}
          alt={name}
        />
      );
    case 134:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Volkswagen.webp`)}
          alt={name}
        />
      );
    case 272:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Hyundai.webp`)}
          alt={name}
        />
      );
    case 352:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Yamaha.webp`)}
          alt={name}
        />
      );

    default:
      return (
        <div>
          <p>{name}</p>
        </div>
      );
  }
};

export default function CardMarca({ data, infoJson }) {
  const location = `${
    infoJson.Layout.Carros.existe ? "/usadas/" : "/viaturas/usadas/"
  }${LimparToUrl(data.Marca.Nome)}/${
    infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""
  }`;

  return (
    <Col xs={12}>
      <Link to={location}>
        <Col className={`listagem_info  ${css.retangle}`}>
          {selectImage(data.Marca.Id, data.Marca.Nome, infoJson)}
        </Col>
      </Link>
    </Col>
  );
}
