import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import messenger from "../../assets/icons/redes/messenger.svg";
import whats from "../../assets/icons/redes/whats.svg";
import css from "./faleConnosco.module.css";

import { tratarLink } from "../helpers/tratarInfoEmpresa";
import { ReactComponent as Fechar } from "../../assets/icons/modal/fechar.svg";
import { ReactComponent as LigueMe } from "../../assets/icons/redes/ligue_me.svg";
import { ReactComponent as Telemovel } from "../../assets/icons/redes/telemovel.svg";
import { ReactComponent as Telefone } from "../../assets/icons/redes/telefone.svg";
import { ReactComponent as Email } from "../../assets/icons/redes/email.svg";
import ModalLigueMe from "../tools/dot/ModalLigueMe";

export default function ModalFaleConnosco({
  show,
  onHide,
  allinfo,
  infoJson,
  nodeRef,
}) {
  const [showLigame, setShowLigame] = useState(false);

  return (
    <>
      <div className={css.zone}></div>
      <section ref={nodeRef} className={` ${css.box} listagem_info `}>
        <div onClick={() => onHide()} className={` ${css.close} close`}>
          <Fechar />
        </div>
        <section className={css.retangle}>
          <Container>
            <Col className={css.Titulo} xs={12}>
              Telefone
            </Col>

            {allinfo.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.Telefone && (
                    <Col xs={12} className={css.BoderBottom}>
                      <Row>
                        <Col>
                          <Col className={css.local}>{item.Nome}</Col>
                          <Col className={css.number}>{item.Telefone}</Col>
                        </Col>
                        <Col className={css.divBtn}>
                          <a href={tratarLink(item.Telefone, "Telefone")}>
                            <Col className={`${css.btn} btnPesquisa`}>
                              <Telefone className={` `} stroke={"#fff"} />
                              LIGAR
                            </Col>
                          </a>
                        </Col>
                        {item.CustoChamadaTelefone && (
                          <Col xs={12} className={css.local}>
                            ({item.CustoChamadaTelefone})
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
            <Col className={css.Titulo} xs={12}>
              Telemovel
            </Col>

            {allinfo.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.Telemovel && (
                    <Col xs={12} className={css.BoderBottom}>
                      <Row>
                        <Col>
                          <Col className={css.local}>{item.Nome}</Col>
                          <Col className={css.number}>{item.Telemovel}</Col>
                        </Col>
                        <Col className={css.divBtn}>
                          <a href={tratarLink(item.Telemovel, "Telemovel")}>
                            <Col className={`${css.btn} btnPesquisa`}>
                              <Telemovel className={` `} stroke={"#fff"} />
                              LIGAR
                            </Col>
                          </a>
                        </Col>
                        {item.CustoChamadaTelemovel && (
                          <Col xs={12} className={css.local}>
                            ({item.CustoChamadaTelemovel})
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}
                  {item.Telemovel2 && (
                    <Col xs={12} className={css.BoderBottom}>
                      <Row>
                        <Col>
                          <Col className={css.local}>{item.Nome}</Col>
                          <Col className={css.number}>{item.Telemovel2}</Col>
                        </Col>
                        <Col className={css.divBtn}>
                          <a href={tratarLink(item.Telemovel2, "Telemovel")}>
                            <Col className={`${css.btn} btnPesquisa`}>
                              <Telemovel className={` `} stroke={"#fff"} />
                              LIGAR
                            </Col>
                          </a>
                        </Col>
                        {item.CustoChamadaTelemovel2 && (
                          <Col xs={12} className={css.local}>
                            ({item.CustoChamadaTelemovel2})
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}
                  {item.Telemovel3 && (
                    <Col xs={12} className={css.BoderBottom}>
                      <Row>
                        <Col>
                          <Col className={css.local}>{item.Nome}</Col>
                          <Col className={css.number}>{item.Telemovel3}</Col>
                        </Col>
                        <Col className={css.divBtn}>
                          <a href={tratarLink(item.Telemovel3, "Telemovel")}>
                            <Col className={`${css.btn} btnPesquisa`}>
                              <Telemovel className={` `} stroke={"#fff"} />
                              LIGAR
                            </Col>
                          </a>
                        </Col>
                        {item.CustoChamadaTelemovel3 && (
                          <Col xs={12} className={css.local}>
                            ({item.CustoChamadaTelemovel3})
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
            <Col className={css.Titulo} xs={12}>
              Email
            </Col>

            {allinfo.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.Email && (
                    <Col xs={12} className={css.BoderBottom}>
                      <Row>
                        <Col>
                          <Col className={css.local}>{item.Nome}</Col>
                          <Col className={css.number}>{item.Email}</Col>
                        </Col>
                        <Col className={css.divBtn}>
                          <a href={tratarLink(item.Email, "Email")}>
                            <Col className={`${css.btn} btnPesquisa`}>
                              <Email className={` `} stroke={"#fff"} />
                              Email
                            </Col>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
            {infoJson.Layout.LigaMe && (
              <>
                <Col className={css.Titulo} xs={12}>
                  Nós ligamos
                </Col>
                <Col
                  xs={12}
                  style={{ cursor: "pointer" }}
                  className={css.BoderBottom}
                  onClick={() => setShowLigame(true)}
                >
                  <Row>
                    <Col>
                      <Col className={css.local}>Insira aqui o seu número</Col>
                      <Col className={css.number}>O seu número</Col>
                    </Col>
                    <Col className={css.divBtn}>
                      <Col className={`${css.btn} btnPesquisa`}>
                        <LigueMe
                          width="17px"
                          height="17px"
                          className={` `}
                          stroke={"#fff"}
                        />
                        Liga-Me
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </>
            )}

            <Col className={css.Titulo} xs={12}>
              Redes Sociais
            </Col>

            {allinfo.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.Whatsapp && (
                    <Col xs={12} className={css.BoderBottom}>
                      <Row>
                        <Col>
                          <Col className={css.local}>{item.Nome}</Col>
                          <Col className={css.number}>Whatsapp</Col>
                        </Col>
                        <Col className={css.divBtn}>
                          <a href={tratarLink(item.Whatsapp, "Whatsapp")}>
                            <Col className={`${css.btnRedes} btn_ficha`}>
                              <img
                                height={"28px"}
                                width={"29px"}
                                src={whats}
                                alt="whats"
                              />
                              Whatsapp
                            </Col>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {item.LinkMessenger && (
                    <Col xs={12} className={css.BoderBottom}>
                      <Row>
                        <Col>
                          <Col className={css.local}>{item.Nome}</Col>
                          <Col className={css.number}>LinkMessenger</Col>
                        </Col>
                        <Col className={css.divBtn}>
                          <a href={tratarLink(item.LinkMessenger, "Messenger")}>
                            <Col className={`${css.btnRedes} btn_ficha`}>
                              <img
                                height={"28px"}
                                width={"33px"}
                                src={messenger}
                                alt="whats"
                              />
                              Messenger
                            </Col>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
          </Container>
        </section>
      </section>
      <ModalLigueMe
        infoJson={infoJson}
        allinfo={allinfo}
        show={showLigame}
        setShow={setShowLigame}
      />
    </>
  );
}
