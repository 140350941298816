"use strict";
/**
 * JSON-LD is a format for encoding structured data in the Internet.
 * Its popularity is due to the fact Google uses it and advocates websites to use it.
 * There is a lot of different formats for publishing data on JSON-LD but since
 * my own use-case is only for publishing blog posts I'll be using but a small subset
 * of them.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateBlogPosting = exports.generateOrganization = exports.generateBreadcrumbList = exports.generateWebsite = void 0;
/**
 * This schema is the bread and butter of websites. It's a simple description which
 * only benefit is I guess that Google might showcase your site in a search result.
 * (if all stars are aligned)
 * @param props
 */
function generateWebsite(_a) {
    var author = _a.author, datePublished = _a.datePublished, description = _a.description, language = _a.language, title = _a.title, url = _a.url, image = _a.image, site = _a.site;
    return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        datePublished: datePublished,
        description: description,
        image: image,
        inLanguage: language || 'en-US',
        name: title,
        url: url,
        author: author && generatePerson(author),
        potentialAction: site && site.searchUrl && {
            '@type': 'SearchAction',
            target: site.searchUrl + "{search_term_string}",
            'query-input': 'required name=search_term_string',
        },
    };
}
exports.generateWebsite = generateWebsite;
function generatePerson(_a) {
    var email = _a.email, name = _a.name, image = _a.image;
    return {
        '@type': 'Person',
        name: name,
        email: email,
        image: image,
    };
}
/**
 * Breadcrumb is a nice way to show the structure of your site. People and bots both respect that.
 * The breadcrumb will be showed in the search result directly, so it's important to get it right.
 * I think Google bot tries to do by itself, but I guess it's helpful if you add it by yourself too.
 *
 * https://developers.google.com/search/docs/guides/enhance-site#enable-breadcrumb
 */
function generateBreadcrumbList(breadcrumbList) {
    return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbList.map(function (b, i) { return ({
            '@type': 'ListItem',
            position: i,
            item: {
                '@id': b.url,
                '@type': 'WebPage',
                url: b.url,
                name: b.title,
                image: b.image,
            }
        }); })
    };
}
exports.generateBreadcrumbList = generateBreadcrumbList;
function generateOrganization(organization) {
    return {
        '@context': 'http://schema.org',
        '@type': organization['@type'] ? organization['@type'] : 'Organization',
        description: organization.description,
        name: organization.name,
        legalName: organization.legalName,
        sameAs: organization.sameAs,
        url: organization.url,
        logo: organization.logo,
        parentOrganization: organization.parentOrganization && generateOrganization(organization.parentOrganization),
    };
}
exports.generateOrganization = generateOrganization;
/**
 * This is a general BlogPosting schema which covers quite a few attributes.
 * @param props
 */
function generateBlogPosting(_a) {
    var url = _a.url, title = _a.title, description = _a.description, image = _a.image, datePublished = _a.datePublished, dateModified = _a.dateModified, tags = _a.tags, author = _a.author, publisher = _a.publisher;
    return {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: url,
        name: title,
        // From https://developers.google.com/search/docs/data-types/article#article_types
        // "The headline of the article. Headlines should not exceed 110 characters."
        headline: title,
        keywords: tags,
        description: description,
        author: author && generatePerson(author),
        publisher: publisher && generateOrganization(publisher),
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': url,
        },
        // From https://developers.google.com/search/docs/data-types/article#article_types
        // "Images should be at least 1200 pixels wide."
        image: image,
        // thumbnailUrl ?
        datePublished: datePublished,
        dateModified: dateModified,
    };
}
exports.generateBlogPosting = generateBlogPosting;
