import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Tab, TabContent, Tabs } from "react-bootstrap";
import FormPesquisaAvancada from "./FormPesquisaAvancada";
import css from "../pesquisa.module.css";

import { ReactComponent as Comercial } from "../../../../../assets/icons/tipo/comerc.svg";
import { ReactComponent as Berlina } from "../../../../../assets/icons/tipo/berlina.svg";
import { ReactComponent as Motos } from "../../../../../assets/icons/tipo/motos.svg";
import { ReactComponent as Classico } from "../../../../../assets/icons/tipo/class.svg";

import { ReactComponent as Seta } from "../../../../../assets/icons/pesquisa/seta_pesquisa.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery ";

export default function PesquisaAvancada({
  ArrayComerciais,
  ArrayClassicos,
  allListaCarros,
  setFilteredCarros,
  infoJson,
  allListaMotas,
  setAvancada,
  avancada,
  allLista,
}) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [key, setKey] = useState("");

  let { viatura } = useParams();

  useEffect(() => {
    if (viatura) {
      switch (viatura) {
        case "viaturas":
        case "automoveis":
          setKey("automoveis");
          break;
        case "comerciais":
          setKey("comerciais");
          break;
        case "classicos":
          setKey("classicos");
          break;
        case "motas":
          setKey("motas");
          break;
        default:
          setKey("automoveis");
          break;
      }
    } else {
      setKey("automoveis");
    }
  }, [viatura]);

  return (
    <>
      <div className={` ${!avancada ? "d-none" : "d-block"}`}>
        {isMobile && (
          <Col xs={12}>
            <div
              onClick={() => {
                setAvancada(false);
              }}
              style={{ marginTop: 0 }}
              className={`${css.textoLimpar} textoLimpar `}
            >
              <Seta style={{ marginBottom: 0, marginRight: "5px" }} /> pesquisa
              Rapida
            </div>
          </Col>
        )}

        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className={`mb-3 ${css.carroucel}`}
          activeKey={key}
          onSelect={(k) => setKey(k)}
          mountOnEnter={true}
        >
          {allListaCarros.length !== 0 && (
            <Tab
              eventKey="automoveis"
              title={
                <>
                  <div
                    onClick={() =>
                      navigate(
                        `${
                          infoJson.Layout.TudoJunto
                            ? infoJson.Layout.Carros.existe
                              ? "/automoveis/#listagem_viaturas"
                              : "/viaturas/automoveis"
                            : "/#listagem_viaturas"
                        }`,
                        { replace: true, state: { pesquisaAvancada: true } }
                      )
                    }
                    className={`${css.tab} pesquisa-tab`}
                  >
                    <Berlina />
                    <div>Automóveis</div>
                    <div></div>
                  </div>
                </>
              }
            >
              <TabContent>
                <FormPesquisaAvancada
                  ArrayCarros={allListaCarros}
                  setFilteredCarros={setFilteredCarros}
                  url={viatura ? viatura : "/"}
                  tipo={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "automoveis"
                        : "viaturas/automoveis"
                      : "automoveis"
                  }
                  infoJson={infoJson}
                  tipoViatura={1}
                  allLista={allLista}
                />
              </TabContent>
            </Tab>
          )}
          {ArrayComerciais.length !== 0 && (
            <Tab
              eventKey="comerciais"
              title={
                <div
                  onClick={() =>
                    navigate(
                      `${
                        infoJson.Layout.TudoJunto
                          ? infoJson.Layout.Carros.existe
                            ? "/comerciais/#listagem_viaturas"
                            : "/viaturas/comerciais"
                          : "/#listagem_viaturas"
                      }`,
                      { replace: true, state: { pesquisaAvancada: true } }
                    )
                  }
                  className={`${css.tab} pesquisa-tab`}
                >
                  <Comercial />
                  <div>Comerciais</div>
                  <div></div>
                </div>
              }
            >
              <TabContent>
                <FormPesquisaAvancada
                  ArrayCarros={ArrayComerciais}
                  setFilteredCarros={setFilteredCarros}
                  url={viatura ? viatura : "/"}
                  tipo={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "comerciais"
                        : "viaturas/comerciais"
                      : "carros/comerciais/"
                  }
                  infoJson={infoJson}
                  tipoViatura={1}
                  allLista={allLista}
                />
              </TabContent>
            </Tab>
          )}
          {ArrayClassicos.length !== 0 && (
            <Tab
              eventKey="classicos"
              title={
                <div
                  onClick={() =>
                    navigate(
                      `${
                        infoJson.Layout.TudoJunto
                          ? infoJson.Layout.Carros.existe
                            ? "/classicos/#listagem_viaturas"
                            : "/viaturas/classicos"
                          : "/#listagem_viaturas"
                      }`,
                      { replace: true, state: { pesquisaAvancada: true } }
                    )
                  }
                  className={`${css.tab} pesquisa-tab`}
                >
                  <Classico />
                  <div>Clássicos</div>
                  <div></div>
                </div>
              }
            >
              <TabContent>
                <FormPesquisaAvancada
                  ArrayCarros={ArrayClassicos}
                  setFilteredCarros={setFilteredCarros}
                  url={viatura ? viatura : "/"}
                  tipo={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "classicos"
                        : "viaturas/classicos"
                      : "carros/classicos/"
                  }
                  infoJson={infoJson}
                  tipoViatura={1}
                  allLista={allLista}
                />
              </TabContent>
            </Tab>
          )}
          {allListaMotas.length !== 0 && (
            <Tab
              eventKey="motas"
              title={
                <div
                  onClick={() =>
                    navigate(
                      `${
                        infoJson.Layout.TudoJunto
                          ? infoJson.Layout.Carros.existe
                            ? "/motas/#listagem_viaturas"
                            : "/viaturas/motas"
                          : "/motas/usadas/"
                      }`,
                      { replace: true, state: { pesquisaAvancada: true } }
                    )
                  }
                  className={`${css.tab} pesquisa-tab`}
                >
                  <Motos />
                  <div>Motas</div>
                  <div></div>
                </div>
              }
            >
              <TabContent>
                <FormPesquisaAvancada
                  ArrayCarros={allListaMotas}
                  setFilteredCarros={setFilteredCarros}
                  url={viatura ? viatura : "/"}
                  tipo={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "motas"
                        : "viaturas/motas"
                      : "motas/usadas/"
                  }
                  infoJson={infoJson}
                  tipoViatura={2}
                  allLista={allLista}
                />
              </TabContent>
            </Tab>
          )}

          {/*
          <Tab eventKey="classicos" title="Clássicos">
            <TabContent></TabContent>
          </Tab>

          <Tab eventKey="barcos" title="Barcos">
            <TabContent></TabContent>
          </Tab> */}
          {!isMobile && (
            <Tab
              tabClassName="ola"
              title={
                <>
                  <div
                    onClick={() => {
                      setAvancada(false);
                    }}
                    className={`pesquisa-tab ${css.tab} ${
                      !avancada ? "d-none" : "d-flex"
                    }`}
                    style={{ flexDirection: "initial" }}
                  >
                    <Seta
                      style={{
                        marginBottom: 0,
                        marginRight: "5px",
                        height: "25px",
                      }}
                    />
                    pesquisa Rapida
                  </div>
                </>
              }
            ></Tab>
          )}
        </Tabs>
      </div>
    </>
  );
}
