import React, { useState } from "react";
import messenger from "../../../assets/icons/redes/messenger.svg";
import whats from "../../../assets/icons/redes/whats.svg";
import css from "./dot.module.css";
import { tratarLink } from "../../helpers/tratarInfoEmpresa";
import { ReactComponent as Ligar } from "../../../assets/icons/redes/liga.svg";
import { ReactComponent as LigueMe } from "../../../assets/icons/redes/ligue_me.svg";
import { ReactComponent as Email } from "../../../assets/icons/redes/email.svg";
import DotContacto from "./DotContacto";
import ModalLigueMe from "./ModalLigueMe";

export default function DotContactos({ infoJson, allinfo }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div>
        {allinfo.Whatsapp && (
          <DotContacto
            link={tratarLink(allinfo.Whatsapp, "Whatsapp")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              height="34px"
              width={"35px"}
              className={` `}
              src={whats}
              alt="Whatsapp"
            />
            Whatsapp
          </DotContacto>
        )}
        {allinfo.LinkMessenger && (
          <DotContacto
            link={tratarLink(allinfo.LinkMessenger, "Messenger")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              height="29px"
              width={"34px"}
              className={` `}
              src={messenger}
              alt="Messenger"
            />
            Messenger
          </DotContacto>
        )}
        {allinfo.Telefone && (
          <DotContacto link={tratarLink(allinfo.Telefone, "Telefone")}>
            <Ligar height="22px" width="22px" className="SVGColor" />
            Ligar
          </DotContacto>
        )}
        {allinfo.Email && (
          <DotContacto link={tratarLink(allinfo.Email, "Email")}>
            <Email height="20px" width="24px" className="SVGColor" />
            Email
          </DotContacto>
        )}
        {infoJson.Layout.LigaMe && (
          <>
            <div className={`${css.box}`} onClick={() => setShow(true)}>
              <LigueMe height="22px" width="22px" className="SVGColor" />
              Liga-Me
            </div>
          </>
        )}
      </div>
      <ModalLigueMe
        infoJson={infoJson}
        allinfo={allinfo}
        show={show}
        setShow={setShow}
      />
    </>
  );
}
