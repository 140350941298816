import { Select } from "react-functional-select";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { ReactComponent as Arrow } from "../../../../../assets/icons/pesquisa/select-seta.svg";
import { ReactComponent as Clear } from "../../../../../assets/icons/pesquisa/fechar-select.svg";
import { ButtonMuiltiSelect } from "../../../../helpers/tratarInfo";
import css from "../pesquisa.module.css";

export default function ExtrasSelect({
  Array,
  onChange,
  UpdateSelectRef,
  ArraySelect,
  infoJson,
}) {
  const selectRef = useRef();
  const [option, setOption] = useState([]);
  const getOptionValue = useCallback((option) => option, []);
  const getOptionLabel = useCallback((option) => option, []);

  const onOptionChange = (option) => {
    onChange(option);
  };

  // useEffect(() => {
  //   selectRef.current?.setValue(ArraySelect);
  // }, [ArraySelect]);


  useEffect(() => {
    const allListaPorVender = Array.filter((carro) => {
      return carro.Vendido === false;
    });
    const ExtrasSoltos = allListaPorVender.map((extras) => {
      return extras.ExtrasSoltos;
    });

    const item = ExtrasSoltos.toString()
      .split(",")
      .map((extras) => {
        return extras.trim();
      })
      .filter((item) => item !== "")
      .sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, extra) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === extra)) {
          accumulator.push(extra);
        }
        return accumulator;
      }, []);

    setOption(item);
    UpdateSelectRef(selectRef);
  }, [Array, UpdateSelectRef]);
  const clear = (
    <span>
      <Clear fill={infoJson.Cores.SVG} width="10px" />
    </span>
  );
  const arrow = (
    <span>
      <Arrow fill={infoJson.Cores.SVG} className={css.arrow} />
    </span>
  );
  return (
    <Select
      ref={selectRef}
      isMulti
      isClearable
      isSearchable
      options={option}
      initialValue={ArraySelect}
      placeholder="Selecionar"
      onOptionChange={onOptionChange}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      openMenuOnClick={true}
      blurInputOnSelect={false}
      // closeMenuOnSelect={false}
      hideSelectedOptions={true}
      filterIgnoreCase={true}
      filterIgnoreAccents={true}
      noOptionsMsg="Não existe"
      clearIcon={clear}
      caretIcon={arrow}
      themeConfig={ButtonMuiltiSelect(infoJson)}
    />
  );
}
