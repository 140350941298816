import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import css from "./cookieConsentir.module.css";

export default function CookieConsentir() {
  return (
    <CookieConsent
      buttonText="Aceitar"
      buttonClasses={`${css.btn}`}
      containerClasses={`cookies`}
      expires={150}
      style={{ zIndex: 1101 }}
    >
      <p className={`${css.p}`}>
        Ao usar o nosso website, você está a concordar com o uso de cookies de
        acordo com a nossa{" "}
        <Link className={`${css.Link}`} to="/privacidade/">
          política de cookies
        </Link>
        .
      </p>
    </CookieConsent>
  );
}
