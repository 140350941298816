import React from "react";
import css from "./menuMobile.module.css";
import InfoMenu from "./InfoMenu";
import face from "../../../../assets/icons/redes/face.svg";
import insta from "../../../../assets/icons/redes/insta.svg";
import youtube from "../../../../assets/icons/redes/youtube.svg";
import { tratarLink } from "../../../helpers/tratarInfoEmpresa";
export default function MenuRedes({ allinfo }) {
  return (
    <div className="col p-3">
      <div className={`${css.menuMobileTitulo} cor-titulo `}>
        Siga as nossas novidades
      </div>
      <div className={`${css.menuMobileRedes} col nopadding `}>
        {allinfo.LinkFacebook && (
          <>
            <InfoMenu
              link={tratarLink(allinfo.LinkFacebook, "Facebook")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`menu ${css.btn}`}>
                <img height="29px" className={` `} src={face} alt="Facebook" />
              </div>
              Facebook
            </InfoMenu>
            <div className={css.with}></div>
          </>
        )}
        {allinfo.LinkYoutube && (
          <>
            <InfoMenu
              link={tratarLink(allinfo.LinkYoutube, "LinkYoutube")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`menu ${css.btn}`}>
                <img
                  height="25px"
                  className={` `}
                  src={youtube}
                  alt="LinkYoutube"
                />
              </div>
              Youtube
            </InfoMenu>
            <div className={css.with}></div>
          </>
        )}

        {allinfo.LinkInstagram && (
          <>
            <InfoMenu
              link={tratarLink(allinfo.LinkInstagram, "Instagram")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`menu ${css.btn}`}>
                <img
                  height="30px"
                  className={` `}
                  src={insta}
                  alt="Instagram"
                />
              </div>
              Instagram
            </InfoMenu>
            <div className={css.with}></div>
          </>
        )}
      </div>
    </div>
  );
}
