import React, { useState } from "react";
import bandeiras1 from "../../../../assets/icons/bandeiras/PT.webp";
import bandeiras2 from "../../../../assets/icons/bandeiras/FR.webp";
import bandeiras3 from "../../../../assets/icons/bandeiras/GB.webp";
import bandeiras4 from "../../../../assets/icons/bandeiras/DE.webp";

import css from "../header.module.css";
import { NavDropdown } from "react-bootstrap";
import MobileDetect from "mobile-detect";

export default function TradutorHome() {
  let userAgent;
  let deviceType;

  userAgent = navigator.userAgent;
  const md = new MobileDetect(userAgent);
  if (md.tablet()) {
    deviceType = "tablet";
  } else if (md.mobile()) {
    deviceType = "mobile";
  } else {
    deviceType = "desktop";
  }

  const [TradutorDropdown, setTradutorDropdown] = useState(false);
  const [src, setSrc] = useState(bandeiras1);

  const changeEvent = (el) => {
    if (el.fireEvent) {
      el.fireEvent("onchange");
    } else {
      var evObj = document.createEvent("HTMLEvents");

      evObj.initEvent("change", false, true);
      el.dispatchEvent(evObj);
    }
  };

  const trocarIdioma = (sigla) => {
    const comboGoogleTradutor = document
      .getElementById("google_translate_element")
      .querySelector(".goog-te-combo:first-child");
    if (comboGoogleTradutor) {
      comboGoogleTradutor.value = sigla;
      changeEvent(comboGoogleTradutor); //Dispara a troca
    }
  };
  return (
    <>
      <NavDropdown
        title={
          <>
            <div className={`${css.boxMenu}   boxMenu`}>
              <img
                className=" p-0  "
                style={{ marginBottom: "12px" }}
                height={`22px`}
                src={src}
                alt="bandeiras1"
              />
              Translate
            </div>
            <span></span>
          </>
        }
        className={css.padding}
        id="basic-nav-dropdown3"
        show={TradutorDropdown}
        onClick={() => {
          deviceType !== "desktop" && setTradutorDropdown(!TradutorDropdown);
        }}
        onMouseEnter={() =>
          deviceType === "desktop" && setTradutorDropdown(true)
        }
        onMouseLeave={() =>
          deviceType === "desktop" && setTradutorDropdown(false)
        }
        renderMenuOnMount={true}
      >
        <NavDropdown.Item
          className={`${css.boxMenuItem} boxMenuItem`}
          onClick={() => {
            setSrc(bandeiras1);
            trocarIdioma("pt");
          }}
        >
          <img
            className=" p-0 me-2 "
            height={`18px`}
            src={bandeiras1}
            alt="bandeiras1"
          />{" "}
          Português
        </NavDropdown.Item>
        <NavDropdown.Item
          className={`${css.boxMenuItem} boxMenuItem`}
          onClick={() => {
            setSrc(bandeiras2);
            trocarIdioma("fr");
          }}
        >
          <img
            className=" p-0 me-2 "
            height={`18px`}
            src={bandeiras2}
            alt="bandeiras2"
          />{" "}
          Francês
        </NavDropdown.Item>
        <NavDropdown.Item
          className={`${css.boxMenuItem} boxMenuItem`}
          onClick={() => {
            setSrc(bandeiras3);
            trocarIdioma("en");
          }}
        >
          <img
            className=" p-0 me-2 "
            height={`18px`}
            src={bandeiras3}
            alt="bandeiras3"
          />{" "}
          Inglês
        </NavDropdown.Item>
        <NavDropdown.Item
          className={`${css.boxMenuItem} boxMenuItem`}
          onClick={() => {
            setSrc(bandeiras4);
            trocarIdioma("de");
          }}
        >
          <img
            className=" p-0 me-2 "
            height={`18px`}
            src={bandeiras4}
            alt="bandeiras4"
          />{" "}
          Alemão
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
}
