import React from 'react'
import { Container } from 'react-bootstrap';
import SectionZone from '../SectionZone';
import CarrouselMarcas from './CarrouselMarcas';

export default function Marcas({ allLista, infoJson }) {
    return (
        <>
          <SectionZone
            Titulo={"Marcas"}
            SubTitulo={"Marcas que Comercializamos"}
            top={0.001}
            bottom={0.001}
          >
            <Container fluid="xxxl" className={`position-relative noPadding-xs`}>
              {allLista.length !== 0 && (
                <CarrouselMarcas allLista={allLista} infoJson={infoJson} />
              )}
            </Container>
          </SectionZone>
        </>
      );
    }