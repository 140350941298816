import { Item } from "react-html-email";
import React from "react";

export const BodyHeader = (props) => (
  <Item style={props.styles.item}>
    <h2 style={props.styles.span}>
      <props.bodyHeaderText />
    </h2>
  </Item>
);
