import React from "react";
import css from "../carrousel.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow/Slider.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CardBannerDestaque from "./CardBannerDestaque";
import useMediaQuery from "../../../hooks/useMediaQuery ";

export default function BannerDestaque({
  ViaturasDestaques,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  alltexto,
  allinfo,
}) {
  const ismobile = useMediaQuery("(max-width: 574px)");
  const responsive = {
    0: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  };

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <Swiper
      className={`Destaques-Banners ${infoJson.Banner.Existe ? "top" : ""}`}
      breakpoints={responsive}
      // autoplay={ismobile ? false : true}
      pagination={
        ismobile
          ? {
              dynamicBullets: true,
            }
          : true
      }
      navigation={
        ismobile
          ? false
          : {
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
              disabledClass: css.buttonDisable,
              lockClass: css.buttonLock,
            }
      }
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      effect={"slide"}
      grabCursor="true"
      centeredSlides={true}
      loop={true}
      speed={500}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {ViaturasDestaques.map((carro) => {
        return (
          <SwiperSlide key={carro.CodViatura}>
            <CardBannerDestaque
              data={carro}
              infoJson={infoJson}
              selectedItems={selectedItems}
              addToCompare={addToCompare}
              removeFromCompare={removeFromCompare}
              alltexto={alltexto}
              allinfo={allinfo}
            />
          </SwiperSlide>
        );
      })}
      {ismobile ? (
        <></>
      ) : (
        <>
          <div
            className={`${css.buttonPrev} ${css.buttonPrevB}`}
            ref={navigationPrevRef}
          >
            <Arrow
              style={{ transform: `rotate(180deg)` }}
              stroke={infoJson.Cores.ArrowSlider}
            />
          </div>
          <div
            className={`${css.buttonNext} ${css.buttonNextB}`}
            ref={navigationNextRef}
          >
            <Arrow stroke={infoJson.Cores.ArrowSlider} />
          </div>
        </>
      )}
    </Swiper>
  );
}
