import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  tratarLink,
  tratarMotorizacao,
  tratarPreco,
  tratarPrecoPromo,
} from "../../helpers/tratarInfoCarros";
import { ReactComponent as Close } from "../../../assets/icons/modal/fechar.svg";
import css from "./CarMin.module.css";
import { yScrollContext } from "../../tools/restoreScroll/yScroll";
import { Picture } from "react-responsive-picture";
export default function CarMin({ carro, removeFromCompare, showValue }) {
  const { yScroll } = useContext(yScrollContext);
  const [imagem, setImagem] = useState("");
  const [srcSet, setSrcSet] = useState("");

  useEffect(() => {
    if (carro.Ficheiros.length !== 0) {
      const ImagemPrincipal = carro.Ficheiros.filter((carro) => {
        return carro.Principal === true;
      });
      setImagem(ImagemPrincipal[0]);
    }
  }, [carro]);

  useEffect(() => {
    if (imagem.Ficheiro) {
      const imagemSeloGrande = imagem.Ficheiro;
      const imagemSeloMedio = imagem.Ficheiro.replace("-6", "-4");
      const imagemSeloPequeno = imagem.Ficheiro.replace("-6", "-2");
      const imageVariants = [
        {
          srcSet: imagemSeloPequeno,
          media: "(max-width: 350px)",
        },
        {
          srcSet: imagemSeloMedio,
          media: "(max-width:991px)",
        },
        {
          srcSet: imagemSeloGrande,
        },
      ];

      setSrcSet(imageVariants);
    }
  }, [imagem]);

  const location = tratarLink(
    carro.Marca.Nome,
    carro.Modelo.Nome,
    carro.CodViatura,
    carro.Viatura
  );
  const Motorizacao = tratarMotorizacao(
    carro.VersaoAlternatica,
    carro.Motorizacao
  );
  const PrecoLimpo = tratarPreco(carro.Preco);
  const PrecoPromoLimpo = tratarPrecoPromo(carro.PrecoPromo);

  return (
    <Col xs={showValue ? 6 : 12} md={6} lg={4}>
      <Row>
        <Col
          xs={12}
          md={4}
          className={`${css.foto} ${
            showValue ? css.dBlockImage : css.dNoneImg
          }`}
        >
          <Link
            to={location}
            state={{
              backward: {
                yscroll: yScroll,
              },
            }}
          >
            {srcSet !== "" && (
              <Picture
                alt={`${carro.Marca.Nome} ${carro.Modelo.Nome}`}
                sources={srcSet}
              />
            )}
          </Link>
        </Col>

        <Col
          xs={12}
          md={8}
          className={` ${css.box} ${!showValue ? css.boxP : ""} `}
        >
          <Link
            to={location}
            state={{
              backward: {
                yscroll: yScroll,
              },
            }}
          >
            <Col xs={12} className={` ${css.marca} `}>
              <span>{carro.Marca.Nome}</span> {carro.Modelo.Nome} {Motorizacao}
            </Col>

            <Col xs={12} className={` ${css.preco} titulo-page`}>
              {carro.Vendido !== true ? (
                <>
                  {carro.NaoExportarPreco !== true && PrecoPromoLimpo !== "" ? (
                    <>{PrecoPromoLimpo}</>
                  ) : carro.NaoExportarPreco !== true && PrecoLimpo !== "" ? (
                    <>{PrecoLimpo}</>
                  ) : (
                    <> Sob/Consulta</>
                  )}
                </>
              ) : (
                <>Vendida</>
              )}
            </Col>
          </Link>

          <span
            className={`${css.remove} ${!showValue ? css.removeP : ""} `}
            onClick={() => removeFromCompare(carro)}
          >
            <Close height="13px" width="13px" className="close" />
            {showValue ? (
              <span> Remover </span>
            ) : (
              <span className="d-none d-md-block"> Remover </span>
            )}
          </span>
        </Col>
      </Row>
    </Col>
  );
}
