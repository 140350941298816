import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { ReactComponent as Listagem } from "../../../../assets/icons/menu/listagem.svg";
import { ReactComponent as Serv } from "../../../../assets/icons/menu/serv.svg";
import { ReactComponent as Topo } from "../../../../assets/icons/menu/voltar_topo.svg";
import { ReactComponent as Pesq } from "../../../../assets/icons/menu/pesq.svg";
import { ReactComponent as Contact } from "../../../../assets/icons/menu/falar_agora.svg";
import css from "../header.module.css";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import MenuServicosBottom from "./MenuServicosBottom";
import MenuViaturaBottom from "./MenuViaturaBottom";
export default function MenuBottom({
  infoJson,
  allListaCarros,
  allListaComercias,
  allListaClassicos,
  allListaMotas,
  isScroll,
  addClass,
  setModalFaleConnosco,
  modalFaleConnosco,
  allinfoText,
  ficha,
  disabled,
  setDisabled,
}) {
  const nodeRef = React.useRef(null);
  const nodeRefV = React.useRef(null);
  const [activeDropS, setactiveDropS] = useState(false);
  const [activeDropV, setactiveDropV] = useState(false);

  useEffect(() => {
    //bloqueia o body
    //para não haver scroll na pagina
    activeDropV || activeDropS
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [activeDropV, activeDropS]);

  const openViaturas = () => {
    activeDropV ? setactiveDropV(false) : setactiveDropV(true);
    activeDropV ? setDisabled(false) : setDisabled(true);
    setactiveDropS(false);
    setModalFaleConnosco(false);
  };
  const openServicos = () => {
    activeDropS ? setactiveDropS(false) : setactiveDropS(true);
    activeDropS ? setDisabled(false) : setDisabled(true);
    setactiveDropV(false);
    setModalFaleConnosco(false);
  };

  const openModal = () => {
    modalFaleConnosco
      ? setModalFaleConnosco(false)
      : setModalFaleConnosco(true);
    modalFaleConnosco ? setDisabled(false) : setDisabled(true);
    setactiveDropS(false);
    setactiveDropV(false);
  };

  const goTop = () => {
    document.documentElement.scrollTo(0, 0);
  };

  return (
    <>
      <Navbar
        className={` ${css.cor_fundo_claro_bottom} corFundoClaroBottom ${
          !isScroll && !ficha ? css.navbarScrollCustomBottom : ""
        } ${addClass}`}
        fixed="bottom"
      >
        <Container>
          <div className="d-flex flex-nowrap w-100">
            {infoJson.Layout.MenuJunto ? (
              <div className={css.padding}>
                <Nav.Link as={NavLink} to={"/viaturas/usadas/"}>
                  <div
                    className={`${css.boxMenu} ${css.boxMenuBottom} ${
                      disabled ? " disable" : ""
                    }  boxMenu`}
                  >
                    <Listagem className=" p-0 mb-2 " height={`27px`} />
                    Viaturas
                  </div>
                  <span></span>
                </Nav.Link>
              </div>
            ) : (
              <div className={css.padding}>
                <div
                  className={`${css.boxMenu} ${disabled ? " disable" : ""} ${
                    css.boxMenuBottom
                  } ${activeDropV ? `${css.active} active ` : ""} boxMenu`}
                  onClick={() => {
                    openViaturas();
                  }}
                >
                  <Listagem className=" p-0 mb-2 " height={`27px`} />
                  Viaturas
                </div>
                <span></span>
              </div>
            )}

            <div className={css.padding}>
              <Nav.Link
                as={Link}
                to={`${
                  infoJson.Layout.Carros.existe ? "/" : "/viaturas/usadas/"
                }`}
                state={{ pesquisaAvancada: true }}
                onClick={() => {
                  setactiveDropS(false);
                  setactiveDropV(false);
                }}
              >
                <div
                  className={`${css.boxMenu} ${disabled ? " disable" : ""} ${
                    css.boxMenuBottom
                  }  boxMenu`}
                >
                  <Pesq className=" p-0 mb-2 " height={`27px`} />
                  Pesquisar
                </div>
                <span></span>
              </Nav.Link>
            </div>

            <div className={css.padding}>
              <div
                className={`${css.boxMenu} ${disabled ? " disable" : ""} ${
                  css.boxMenuBottom
                } ${activeDropS ? `${css.active} active ` : ""} boxMenu`}
                onClick={() => {
                  openServicos();
                }}
              >
                <Serv className=" p-0 mb-2 " height={`27px`} />
                Serviços
              </div>
              <span></span>
            </div>
            {/* 
            {infoJson.Empresa.existe && (
              <div className={css.padding}>
                <Nav.Link
                  as={NavLink}
                  to={
                    allinfoText.Empresa && allinfoText.Empresa.Url !== ""
                      ? allinfoText.Empresa.Url
                      : infoJson.Empresa.url
                  }
                >
                  <div
                    className={`${css.boxMenu} ${css.boxMenuBottom} ${
                      disabled ? " disable" : ""
                    }  boxMenu`}
                  >
                    <Emp className=" p-0 mb-2 " height={`27px`} />
                    Empresa
                  </div>
                  <span></span>
                </Nav.Link>
              </div>
            )} */}
            <div className={css.padding}>
              <div
                className={`${css.boxMenu} ${disabled ? " disable" : ""} ${
                  css.boxMenuBottom
                } ${modalFaleConnosco ? `${css.active} active ` : ""} boxMenu`}
                onClick={() => {
                  openModal();
                }}
              >
                <Contact className=" p-0 mb-2 " />
                Falar agora
              </div>
              <span></span>
            </div>
            <div className={css.padding}>
              <div
                className={`${css.boxMenu} ${css.boxMenuBottom} ${
                  disabled ? " disable" : ""
                }  boxMenu`}
                onClick={() => {
                  goTop();
                }}
              >
                <Topo className=" p-0 mb-2 " />
                Go Top
              </div>
              <span></span>
            </div>
          </div>
        </Container>
      </Navbar>

      <CSSTransition
        nodeRef={nodeRef}
        in={activeDropS}
        unmountOnExit
        timeout={100}
        classNames={{
          enterActive: `${css.opcoesEnter}`,
          enterDone: `${css.opcoesEnterActive}`,
          exit: `${css.opcoesExit}`,
          exitActive: `${css.opcoesExitActive}`,
        }}
      >
        <MenuServicosBottom
          onHide={() => {
            setactiveDropS(false);
            setDisabled(false);
          }}
          infoJson={infoJson}
          nodeRef={nodeRef}
          allinfoText={allinfoText}
        />
      </CSSTransition>
      <CSSTransition
        nodeRef={nodeRefV}
        in={activeDropV}
        unmountOnExit
        timeout={100}
        classNames={{
          enterActive: `${css.opcoesEnter}`,
          enterDone: `${css.opcoesEnterActive}`,
          exit: `${css.opcoesExit}`,
          exitActive: `${css.opcoesExitActive}`,
        }}
      >
        <MenuViaturaBottom
          onHide={() => {
            setactiveDropV(false);
            setDisabled(false);
          }}
          infoJson={infoJson}
          nodeRef={nodeRefV}
          allListaCarros={allListaCarros}
          allListaComercias={allListaComercias}
          allListaClassicos={allListaClassicos}
          allListaMotas={allListaMotas}
        />
      </CSSTransition>
    </>
  );
}
