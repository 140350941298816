import React, { useEffect, useState } from "react";
import css from "./dot.module.css";
import { ReactComponent as Contact } from "../../../assets/icons/menu/falar_agora.svg";
import { Container } from "react-bootstrap";
import DotContactos from "./DotContactos";
import { isElementInViewport } from "../../helpers/tratarInfo";

export default function Dot({ infoJson, allinfo, selectedItems }) {
  const [show, setShow] = useState(false);

  const [offset, setOffset] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    document.getElementById("infoTop")
      ? setIsVisible(isElementInViewport(document.getElementById("infoTop")))
      : setIsVisible(true);
  }, [offset]);

  return (
    <section className={`${css.section} `}>
      <Container fluid="xxxl" className="text-end">
        <div className={`${css.retangle}`}>
          <div
            className={`${css.position} dot-bg ${
              selectedItems.length > 0 ? css.bottom : ""
            } ${show ? css.open : ""}  ${
              isVisible ? "" : css.navbarScrollCustom
            }`}
          >
            <DotContactos infoJson={infoJson} allinfo={allinfo} />
          </div>
          <div
            className={`${css.dot} dot ${
              selectedItems.length > 0 ? css.bottom : ""
            }   ${isVisible ? "" : css.navbarScrollCustomDot}`}
            onClick={() => setShow(!show)}
          >
            <Contact
              stroke="#fff"
              style={{ height: "30px", width: "auto" }}
              className=" p-0 mb-2 "
            />
            Fale agora
          </div>
        </div>
      </Container>
    </section>
  );
}
